import React from 'react';
import styles from '../../SvgIllustration.module.scss';

const IconLogos = () => {
  return (
    <g className={'illustrationLayer'} fill="none" fillRule="evenodd">
      <path
        id="Mask"
        className={styles.neutral7}
        fill="currentColor"
        fillRule="nonzero"
        d="M152.084 124.094C147.778 124.097 144.03 121.144 143.024 116.953C143.011 116.897
        142.971 116.852 142.917 116.831C141.693 116.338 140.511 115.746 139.382 115.061C139.35
        115.041 139.324 115.011 139.311 114.975C137.66 110.694 137.236 106.037 138.085
        101.527C138.097 101.463 138.144 101.41 138.207 101.392H138.242C138.294 101.387 138.345
        101.406 138.382 101.443C140.658 103.905 143.416 105.873 146.484 107.223C146.506 107.233
        146.53 107.238 146.554 107.238C146.59 107.239 146.625 107.227 146.655 107.206C150.706
        104.293 156.334 105.102 159.402 109.04C159.435 109.082 159.485 109.106 159.539
        109.106H159.562C160.411 108.99 161.276 108.827 162.126 108.624C167.193 107.393
        171.745 104.596 175.135 100.63C175.168 100.591 175.217 100.569 175.268 100.569C175.296
        100.569 175.325 100.576 175.35 100.589C175.421 100.628 175.457 100.711 175.435 100.789C173.392
        108.016 168.159 113.911 161.228 116.791C161.174 116.811 161.134 116.856 161.121 116.912C160.139
        121.115 156.397 124.089 152.084 124.094ZM134.714 111.312C134.668 111.313 134.623
        111.294 134.59 111.261C129.326 105.905 126.84 98.4095 127.859 90.9663C127.866 90.9105
        127.847 90.8545 127.805 90.8162C124.67 87.8654 123.966 83.1472 126.104 79.4088C128.242
        75.6703 132.663 73.8879 136.793 75.0994C136.848 75.1152 136.906 75.1036 136.95 75.0683C137.99
        74.2414 139.097 73.5023 140.259 72.8587C140.284 72.8446 140.313 72.8373 140.342 72.8373C144.895
        73.5422 149.16 75.5107 152.651 78.5193C152.701 78.5619 152.723 78.6292 152.707 78.693C152.691
        78.7572 152.64 78.807 152.576 78.8217C149.298 79.5613 146.207 80.9701 143.498 82.9599C143.448
        82.9963 143.421 83.0562 143.427 83.1175C143.46 83.4309 143.476 83.7458 143.476 84.061C143.445
        88.6871 140.039 92.5951 135.464 93.2552C135.403 93.2637 135.35 93.3046 135.327 93.3624C135.006
        94.1632 134.71 94.9919 134.464 95.8282C132.998 100.83 133.141 106.167 134.873 111.083C134.89
        111.135 134.882 111.192 134.851 111.237C134.82 111.282 134.769 111.31 134.714 111.312ZM165.2
        102.638C165.145 102.638 165.093 102.612 165.06 102.568C165.027 102.523 165.017 102.466 165.034
        102.413C166.014 99.2489 166.342 95.9185 165.998 92.6237C165.991 92.563 165.953 92.5102 165.898
        92.4843C162.625 90.9559 160.525 87.6761 160.505 84.061C160.499 82.8437 160.734 81.6374 161.196
        80.5113C161.219 80.454 161.21 80.3886 161.172 80.3397C160.614 79.6102 160.018 78.9106 159.387
        78.2438C155.792 74.4657 151.102 71.9152 145.979 70.9536C145.899 70.9386 145.84 70.8699 145.837
        70.7885C145.835 70.7069 145.889 70.6343 145.968 70.6138C153.301 68.7175 161.099 70.3681 167.037
        75.0736C167.067 75.0977 167.105 75.111 167.144 75.1112C167.161 75.1114 167.179 75.1089 167.195
        75.1037C168.038 74.8497 168.913 74.7204 169.793 74.7199C174.925 74.7199 179.112 78.8892 179.125
        84.0138C179.124 86.5912 178.06 89.0538 176.184 90.8194C176.142 90.8577 176.122 90.9137 176.13
        90.9695C176.277 92.049 176.351 93.1371 176.353 94.2265C176.353 94.3283 176.346 94.428 176.339
        94.5277L176.327 94.7186C176.327 94.7577 176.313 94.7955 176.289 94.8258C173.41 98.4058 169.588
        101.109 165.255 102.631L165.2 102.638Z"
      />
    </g>
  );
};

export default IconLogos;
