import React from 'react';
import styles from '../../SvgIllustration.module.scss';

const DataTable = () => {
  return (
    <g className={'illustrationLayer'} fill="none" fillRule="evenodd">
      <path
        className={styles.neutral9}
        fill="currentColor"
        fillOpacity=".1"
        d="M42.2,38 L261.8,38 C265.77645,38 269,
      41.2235498 269,45.2 L269,147.8 C269,151.77645 265.77645,155 261.8,155 L42.2,155 C38.2235498,155 35,151.77645 35,
      147.8 L35,45.2 C35,41.2235498 38.2235498,38 42.2,38 Z"
      />
      <path
        className={styles.whiteBlack}
        fill="currentColor"
        d="M42.390625,40 L112.46875,40 L112.46875,67 L37,67 L37,45.4 C37,42.4176624 39.413465,
      40 42.390625,40 Z M114.265625,40 L189.734375,40 L189.734375,67 L114.265625,67 L114.265625,40 Z M191.53125,
      40 L261.609375,40 C264.586535,40 267,42.4176624 267,45.4 L267,67 L191.53125,67 L191.53125,40 Z"
      />
      <path
        className={styles.whiteBlack}
        fill="currentColor"
        d="M37,69 L112.46875,69 L112.46875,96.1276596 L37,96.1276596 L37,69 Z M114.265625,
      69 L189.734375,69 L189.734375,96.1276596 L114.265625,96.1276596 L114.265625,69 Z M191.53125,69 L267,
      69 L267,96.1276596 L191.53125,96.1276596 L191.53125,69 Z M37,97.9361702 L112.46875,97.9361702
      L112.46875,125.06383 L37,125.06383 L37,97.9361702 Z M114.265625,97.9361702 L189.734375,97.9361702
      L189.734375,125.06383 L114.265625,125.06383 L114.265625,97.9361702 Z M191.53125,97.9361702 L267,
      97.9361702 L267,125.06383 L191.53125,125.06383 L191.53125,97.9361702 Z M37,126.87234 L112.46875,
      126.87234 L112.46875,154 L42.390625,154 C39.413465,154 37,151.570907 37,148.574468 L37,126.87234
      Z M114.265625,126.87234 L189.734375,126.87234 L189.734375,154 L114.265625,154 L114.265625,126.87234
      Z M191.53125,126.87234 L267,126.87234 L267,148.574468 C267,151.570907 264.586535,154 261.609375,154
      L191.53125,154 L191.53125,126.87234 Z"
      />
      <path
        className={styles.neutral6}
        fill="currentColor"
        d="M51.4911504,49 L84.7256637,49 C87.2060576,
      49 89.2168142,51.0147186 89.2168142,53.5 C89.2168142,55.9852814 87.2060576,58 84.7256637,58 L51.4911504,
      58 C49.0107565,58 47,55.9852814 47,53.5 C47,51.0147186 49.0107565,49 51.4911504,49 Z M131.433628,
      49 L153.889381,49 C156.369774,49 158.380531,51.0147186 158.380531,53.5 C158.380531,55.9852814 156.369774,
      58 153.889381,58 L131.433628,58 C128.953234,58 126.942478,55.9852814 126.942478,53.5 C126.942478,
      51.0147186 128.953234,49 131.433628,49 Z M208.681416,49 L245.50885,49 C247.989243,49 250,51.0147186 250,
      53.5 C250,55.9852814 247.989243,58 245.50885,58 L208.681416,58 C206.201022,58 204.190265,55.9852814 204.190265,
      53.5 C204.190265,51.0147186 206.201022,49 208.681416,49 Z"
      />
      <path
        fill="#B7BFC7"
        d="M130.858974,136.777778 L148.893162,136.777778 C150.885164,136.777778 152.5,
      138.394527 152.5,140.388889 C152.5,142.38325 150.885164,144 148.893162,144 L130.858974,144 C128.866973,
      144 127.252137,142.38325 127.252137,140.388889 C127.252137,138.394527 128.866973,136.777778 130.858974,
      136.777778 Z M208.405983,107.888889 L226.440171,107.888889 C228.432172,107.888889 230.047009,
      109.505638 230.047009,111.5 C230.047009,113.494362 228.432172,115.111111 226.440171,115.111111 L208.405983,
      115.111111 C206.413982,115.111111 204.799145,113.494362 204.799145,111.5 C204.799145,109.505638 206.413982,
      107.888889 208.405983,107.888889 Z M208.405983,136.777778 L254.393162,136.777778 C256.385164,136.777778 258,
      138.394527 258,140.388889 C258,142.38325 256.385164,144 254.393162,144 L208.405983,144 C206.413982,
      144 204.799145,142.38325 204.799145,140.388889 C204.799145,138.394527 206.413982,136.777778 208.405983,
      136.777778 Z M130.858974,107.888889 L139.876068,107.888889 C141.86807,107.888889 143.482906,109.505638 143.482906,
      111.5 C143.482906,113.494362 141.86807,115.111111 139.876068,115.111111 L130.858974,115.111111 C128.866973,
      115.111111 127.252137,113.494362 127.252137,111.5 C127.252137,109.505638 128.866973,107.888889 130.858974,
      107.888889 Z M50.6068376,79 L63.2307692,79 C65.2227706,79 66.8376068,80.6167495 66.8376068,82.6111111 C66.8376068,
      84.6054727 65.2227706,86.2222222 63.2307692,86.2222222 L50.6068376,86.2222222 C48.6148362,86.2222222 47,
      84.6054727 47,82.6111111 C47,80.6167495 48.6148362,79 50.6068376,79 Z M50.6068376,136.777778 L63.2307692,
      136.777778 C65.2227706,136.777778 66.8376068,138.394527 66.8376068,140.388889 C66.8376068,142.38325 65.2227706,
      144 63.2307692,144 L50.6068376,144 C48.6148362,144 47,142.38325 47,140.388889 C47,138.394527 48.6148362,
      136.777778 50.6068376,136.777778 Z M208.405983,79 L237.260684,79 C239.252685,79 240.867521,80.6167495 240.867521,
      82.6111111 C240.867521,84.6054727 239.252685,86.2222222 237.260684,86.2222222 L208.405983,86.2222222 C206.413982,
      86.2222222 204.799145,84.6054727 204.799145,82.6111111 C204.799145,80.6167495 206.413982,79 208.405983,
      79 Z M130.858974,79 L169.632479,
      79 C171.62448,79 173.239316,80.6167495 173.239316,82.6111111 C173.239316,84.6054727 171.62448,
      86.2222222 169.632479,86.2222222 L130.858974,86.2222222 C128.866973,86.2222222 127.252137,84.6054727 127.252137,
      82.6111111 C127.252137,80.6167495 128.866973,79 130.858974,79 Z M50.6068376,107.888889 L79.4615385,
      107.888889 C81.4535399,107.888889 83.0683761,109.505638 83.0683761,111.5 C83.0683761,113.494362 81.4535399,
      115.111111 79.4615385,115.111111 L50.6068376,115.111111 C48.6148362,115.111111 47,113.494362 47,111.5 C47,
      109.505638 48.6148362,107.888889 50.6068376,107.888889 Z"
      />
    </g>
  );
};

export default DataTable;
