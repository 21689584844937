import React from 'react';
import styles from '../../SvgIllustration.module.scss';

/* eslint-disable */
const AccessibilityResources = () => {
  return (
    <g className={'illustrationLayer'} fill="none" fillRule="evenodd">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M147.893 61.4699C129.169 56.8147 111.804 59.2458 103.383 61.6056V123.177C112.981 120.763 129.369 118.878 147.893 123.46L147.893 61.4699ZM154.145 136.329C154.366 136.299 154.588 136.256 154.81 136.198C158.058 135.349 161.272 134.691 164.412 134.194L163.537 133.318L168.946 123.841L167.858 121.228C163.77 121.668 159.514 122.387 155.16 123.461L155.16 61.6228C174.115 56.7623 191.764 59.2204 200.275 61.6056V83.9008L206.362 85.932L207.542 85.3412V60.2205C207.542 57.9583 206.128 55.7987 203.791 55.0623C194.683 52.192 173.987 48.8901 151.829 54.981C129.671 48.8901 108.975 52.192 99.8675 55.0623C97.5306 55.7987 96.1162 57.9583 96.1162 60.2205V129.718C96.1162 133.542 99.7958 135.947 103.139 135.056C111.479 132.834 129.06 131.174 148.241 136.197C148.463 136.255 148.686 136.298 148.907 136.328C149.568 137.017 150.497 137.445 151.526 137.445C152.555 137.445 153.484 137.017 154.145 136.329Z"
        fill="currentColor"
        className={styles.neutral6}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M112 71.5C112 72.8807 113.119 74 114.5 74H137.5C138.881 74 140 72.8807 140 71.5V71.5C140 70.1193 138.881 69 137.5 69H114.5C113.119 69 112 70.1193 112 71.5V71.5Z"
        fill="currentColor"
        className={styles.neutral3}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M112 80.5C112 81.8807 113.119 83 114.5 83H134.5C135.881 83 137 81.8807 137 80.5V80.5C137 79.1193 135.881 78 134.5 78H114.5C113.119 78 112 79.1193 112 80.5V80.5Z"
        fill="currentColor"
        className={styles.neutral3}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M112 89.5C112 90.8807 113.119 92 114.5 92H137.5C138.881 92 140 90.8807 140 89.5V89.5C140 88.1193 138.881 87 137.5 87H114.5C113.119 87 112 88.1193 112 89.5V89.5Z"
        fill="currentColor"
        className={styles.neutral3}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M112 98.5C112 99.8807 113.119 101 114.5 101H133.5C134.881 101 136 99.8807 136 98.5V98.5C136 97.1193 134.881 96 133.5 96H114.5C113.119 96 112 97.1193 112 98.5V98.5Z"
        fill="currentColor"
        className={styles.neutral3}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M112 107.5C112 108.881 113.119 110 114.5 110H137.5C138.881 110 140 108.881 140 107.5V107.5C140 106.119 138.881 105 137.5 105H114.5C113.119 105 112 106.119 112 107.5V107.5Z"
        fill="currentColor"
        className={styles.neutral3}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M214.461 123.952L219.15 132.156L213.289 138.015L205.474 133.328L200.394 135.281L197.659 143.875H189.844L187.108 135.281L182.029 133.328L174.213 138.015L168.352 132.156L173.041 123.952L171.087 119.265L162.491 116.53V108.718L171.087 105.983L173.041 101.295L168.352 93.092L174.213 87.2324L182.029 91.92L187.89 89.1856L189.844 81.3728H197.659L199.613 89.1856L205.474 91.1388L213.289 87.2324L219.15 93.092L214.461 101.295L216.415 105.983L225.012 108.718V116.53L216.415 119.265L214.461 123.952ZM193.751 123.442C196.637 123.442 199.162 122.36 201.326 120.196C203.49 118.033 204.572 115.509 204.572 112.624C204.572 111.182 204.211 109.739 203.851 108.297C203.129 107.215 202.408 105.773 201.326 105.052C200.604 103.97 199.162 103.249 198.08 102.527C196.637 102.167 195.194 101.806 193.751 101.806C190.866 101.806 188.341 102.888 186.177 105.052C184.012 107.215 182.93 109.739 182.93 112.624C182.93 115.509 184.012 118.033 186.177 120.196C188.341 122.36 190.866 123.442 193.751 123.442V123.442Z"
        fill="currentColor"
        className={styles.neutral8}
      />
    </g>
  );
};

export default AccessibilityResources;
