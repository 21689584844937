import React from 'react';
import styles from '../../SvgIllustration.module.scss';

const Toggle = () => {
  return (
    <g className={'illustrationLayer'} fill="none" fillRule="evenodd">
      <rect
        className={styles.neutral2}
        x="64"
        y="77"
        width="38"
        height="38"
        rx="4"
        fill="currentColor"
      />
      <rect
        className={styles.neutral2}
        x="112"
        y="79"
        width="34"
        height="34"
        rx="2"
        fill="currentColor"
        stroke="#009CE3"
        strokeWidth="4"
        strokeLinejoin="round"
      />
      <rect
        className={styles.neutral2}
        x="156"
        y="77"
        width="38"
        height="38"
        rx="4"
        fill="currentColor"
      />
      <rect
        className={styles.neutral2}
        x="202"
        y="77"
        width="38"
        height="38"
        rx="4"
        fill="currentColor"
      />
    </g>
  );
};

export default Toggle;
