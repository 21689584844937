import React from 'react';
import styles from '../../SvgIllustration.module.scss';
/* eslint-disable */
const AccessibilityChecklists = () => {
  return (
    <g className={'illustrationLayer'} fill="none" fillRule="evenodd">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M95 82H81C77.134 82 74 85.134 74 89V103C74 106.866 77.134 110 81 110H95C98.866 110 102 106.866 102 103V89C102 85.134 98.866 82 95 82ZM77.8182 89C77.8182 87.2427 79.2427 85.8182 81 85.8182H95C96.7573 85.8182 98.1818 87.2427 98.1818 89V103C98.1818 104.757 96.7573 106.182 95 106.182H81C79.2427 106.182 77.8182 104.757 77.8182 103V89Z"
        fill="currentColor"
        className={styles.neutral5}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M95 42H81C77.134 42 74 45.134 74 49V63C74 66.866 77.134 70 81 70H95C98.866 70 102 66.866 102 63V49C102 45.134 98.866 42 95 42ZM86.3754 57.8382C85.9235 58.3767 85.1208 58.4469 84.5823 57.9951L81.2674 55.2136C80.729 54.7618 79.9262 54.832 79.4744 55.3705L78.1654 56.9304C77.7136 57.4689 77.7838 58.2716 78.3223 58.7235L85.147 64.4501C85.6855 64.9019 86.4883 64.8317 86.9401 64.2932L97.9025 51.2287C98.3544 50.6903 98.2841 49.8875 97.7457 49.4357L96.1857 48.1267C95.6473 47.6749 94.8445 47.7451 94.3927 48.2836L86.3754 57.8382Z"
        fill="currentColor"
        className={styles.action}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M112 57C112 53.134 115.134 50 119 50H187C190.866 50 194 53.134 194 57V57C194 60.866 190.866 64 187 64H119C115.134 64 112 60.866 112 57V57Z"
        fill="currentColor"
        className={styles.neutral4}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M95 122H81C77.134 122 74 125.134 74 129V143C74 146.866 77.134 150 81 150H95C98.866 150 102 146.866 102 143V129C102 125.134 98.866 122 95 122ZM86.3754 137.838C85.9235 138.377 85.1208 138.447 84.5823 137.995L81.2674 135.214C80.729 134.762 79.9262 134.832 79.4744 135.37L78.1654 136.93C77.7136 137.469 77.7838 138.272 78.3223 138.723L85.147 144.45C85.6855 144.902 86.4883 144.832 86.9401 144.293L97.9025 131.229C98.3544 130.69 98.2841 129.887 97.7457 129.436L96.1857 128.127C95.6473 127.675 94.8445 127.745 94.3927 128.284L86.3754 137.838Z"
        fill="currentColor"
        className={styles.action}
      />
      <rect
        x="112"
        y="130"
        width="20"
        height="14"
        rx="7"
        fill="currentColor"
        className={styles.neutral4}
      />
      <rect
        x="112"
        y="90"
        width="45"
        height="14"
        rx="7"
        fill="currentColor"
        className={styles.neutral4}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M207.532 61.5825C208.838 60.2763 210.569 59.625 212.335 59.625C214.24 59.625 216.18 60.3773 217.678 61.8786L228.12 72.3174C231.004 75.2014 231.14 79.7468 228.42 82.4671L224.724 86.1626C224.042 86.8418 222.906 86.807 222.185 86.086L203.913 67.8102C203.192 67.0893 203.161 65.9538 203.84 65.2746L207.532 61.5825ZM195.961 73.1533C196.282 72.8294 196.706 72.6726 197.142 72.6726C197.619 72.6726 198.11 72.8642 198.486 73.2404L216.762 91.5126C217.48 92.2336 217.518 93.3656 216.846 94.0413L170.524 140.356C169.855 141.032 168.723 140.993 168.002 140.272L149.727 121.997C149.006 121.276 148.967 120.147 149.643 119.471L195.961 73.1533ZM142.134 126.716C142.471 126.378 142.921 126.204 143.367 126.204C143.812 126.204 144.255 126.378 144.6 126.716L163.283 145.399C163.607 145.73 163.791 146.169 163.791 146.632C163.791 147.594 163.011 148.374 162.05 148.374H143.367C142.405 148.374 141.625 147.594 141.625 146.632V127.949C141.625 127.489 141.81 127.047 142.134 126.716Z"
        fill="currentColor"
        className={styles.neutral6}
      />
    </g>
  );
};

export default AccessibilityChecklists;
