import HatchCircleSmall from './hatchings/HatchCircleSmall';
import HatchCircle from './hatchings/HatchCircle';
import HatchDiamond from './hatchings/HatchDiamond';
import HatchDiamondSmall from './hatchings/HatchDiamondSmall';
import HatchHexagon from './hatchings/HatchHexagon';
import HatchHexagonSmall from './hatchings/HatchHexagonSmall';
import HatchOval from './hatchings/HatchOval';
import HatchParallelogram from './hatchings/HatchParallelogram';
import HatchParallelogramSmall from './hatchings/HatchParallelogramSmall';
import HatchParallelogramStretch from './hatchings/HatchParallelogramStretch';
import HatchRectangle from './hatchings/HatchRectangle';
import HatchRectangleShort from './hatchings/HatchRectangleShort';
import HatchRectangleSmall from './hatchings/HatchRectangleSmall';
import HatchRhombus from './hatchings/HatchRhombus';
import HatchRhombusSmall from './hatchings/HatchRhombusSmall';
import HatchSashDouble from './hatchings/HatchSashDouble';
import HatchSashLeft from './hatchings/HatchSashLeft';
import HatchSashMedium from './hatchings/HatchSashMedium';
import HatchSashRight from './hatchings/HatchSashRight';
import HatchSashSmall from './hatchings/HatchSashSmall';
import HatchSashThick from './hatchings/HatchSashThick';
import HatchSashThin from './hatchings/HatchSashThin';
import HatchSquareSmall from './hatchings/HatchSquareSmall';
import HatchSquare from './hatchings/HatchSquare';
import HatchTriangle from './hatchings/HatchTriangle';
import HatchTriangleInverted from './hatchings/HatchTriangleInverted';
import HatchTriangleSmall from './hatchings/HatchTriangleSmall';

import Accessibility from './illustrations/Accessibility';
import AccessibilityAudit from './illustrations/Accessibility-Audit';
import AccessibilityChecklists from './illustrations/Accessibility-Checklists';
import AccessibilityColor from './illustrations/Accessibility-Color';
import AccessibilityResources from './illustrations/Accessibility-Resources';
import AccessibilityText from './illustrations/Accessibility-Text';
import Avatars from './illustrations/Avatars';
import ActionBar from './illustrations/Action-Bar';
import ActionList from './illustrations/Action-List';
import Alert from './illustrations/Alert';
import BarList from './illustrations/BarList';
import ButtonRow from './illustrations/Button-Row';
import Button from './illustrations/Button';
import Buttons from './illustrations/Buttons';
import FloatingActionButton from './illustrations/Floating-Action-Button';
import Card from './illustrations/Card';
import CardGroup from './illustrations/Card-Group';
import Checkbox from './illustrations/Checkbox';
import Colors from './illustrations/Colors';
import ColorsBrand from './illustrations/Colors-Brand';
import ColorsContent from './illustrations/Colors-Content';
import ColorsIdentity from './illustrations/Colors-Identity';
import ColorsUtility from './illustrations/Colors-Utility';
import ColorsTagging from './illustrations/Colors-Tagging';
import ColorsTheme from './illustrations/Colors-Theme';
import ContentSelection from './illustrations/Content-Selection';
import DataCard from './illustrations/Data-Card';
import DataTable from './illustrations/Data-Table';
import Divider from './illustrations/Divider';
import Division from './illustrations/Division';
import Elevation from './illustrations/Elevation';
import Environment from './illustrations/Environment';
import Forms from './illustrations/Forms';
import FormModifier from './illustrations/Form-Modifier';
import FormSection from './illustrations/Form-Section';
import GroupAvatar from './illustrations/Group-Avatar';
import Headline from './illustrations/Headline';
import Histogram from './illustrations/Histogram';
import Icon from './illustrations/Icon';
import IconEffects from './illustrations/Icon-Effects';
import IconFeaturesNavigation from './illustrations/Icon-Features-Navigation';
import IconFeedback from './illustrations/Icon-Feedback';
import IconFiletypes from './illustrations/Icon-Filetypes';
import IconInteractions from './illustrations/Icon-Interactions';
import IconLogos from './illustrations/Icon-Logos';
import IconMediaPlayback from './illustrations/Icon-Media-Playback';
import IconPermissions from './illustrations/Icon-Permissions';
import IconReactions from './illustrations/Icon-Reactions';
import IconSports from './illustrations/Icon-Sports';
import IconTechnology from './illustrations/Icon-Technology';
import IconUIControls from './illustrations/Icon-UI-Controls';
import IconUser from './illustrations/Icon-User';
import IconVideoFeatures from './illustrations/Icon-Video-Features';
import ItemTitle from './illustrations/Item-Title';
import KeyboardShortcut from './illustrations/Keyboard-Shortcut';
import Layering from './illustrations/Layering';
import Layout from './illustrations/Layout';
import Level from './illustrations/Level';
import Legend from './illustrations/Legend';
import Link from './illustrations/Link';
import LookupSelect from './illustrations/Lookup-Select';
import LookupMultiSelect from './illustrations/Lookup-Multi-Select';
import Modal from './illustrations/Modal';
import Module from './illustrations/Module';
import Note from './illustrations/Note';
import Notice from './illustrations/Notice';
import Notification from './illustrations/Notification';
import NumberList from './illustrations/NumberList';
import OrgAvatar from './illustrations/Org-Avatar';
import Overlay from './illustrations/Overlay';
import ParentCheckbox from './illustrations/Parent-Checkbox';
import Radio from './illustrations/Radio';
import SearchInput from './illustrations/Search-Input';
import SegmentedBar from './illustrations/Segmented-Bar';
import SelectMark from './illustrations/Select-Mark';
import Select from './illustrations/Select';
import Selects from './illustrations/Selects';
import Shadows from './illustrations/Shadows';
import SingleSelect from './illustrations/Single-Select';
import Space from './illustrations/Space';
import Spinner from './illustrations/Spinner';
import Subhead from './illustrations/Subhead';
import TeamAvatar from './illustrations/Team-Avatar';
import Text from './illustrations/Text';
import Textarea from './illustrations/Textarea';
import TextInput from './illustrations/Text-Input';
import Themes from './illustrations/Themes';
import Toast from './illustrations/Toast';
import Toggle from './illustrations/Toggle';
import Tooltip from './illustrations/Tooltip';
import Type from './illustrations/Type';
import Typography from './illustrations/Typography';
import UserAvatar from './illustrations/User-Avatar';
import VideoPlayer from './illustrations/Video-Player';

import Hexagon from './shapes/Hexagon';
import Triangle from './shapes/Triangle';
import Circle from './shapes/Circle';
import Diamond from './shapes/Diamond';
import Oval from './shapes/Oval';
import Parallelogram from './shapes/Parallelogram';
import Rectangle from './shapes/Rectangle';
import Rhombus from './shapes/Rhombus';
import Square from './shapes/Square';

export default {
  HatchCircleSmall,
  HatchCircle,
  HatchDiamond,
  HatchDiamondSmall,
  HatchHexagon,
  HatchHexagonSmall,
  HatchOval,
  HatchParallelogram,
  HatchParallelogramSmall,
  HatchParallelogramStretch,
  HatchRectangle,
  HatchRectangleShort,
  HatchRectangleSmall,
  HatchRhombus,
  HatchRhombusSmall,
  HatchSashDouble,
  HatchSashSmall,
  HatchSashLeft,
  HatchSashMedium,
  HatchSashRight,
  HatchSashThin,
  HatchSashThick,
  HatchSquare,
  HatchSquareSmall,
  HatchTriangle,
  HatchTriangleInverted,
  HatchTriangleSmall,
  Hexagon,
  Triangle,
  Circle,
  Diamond,
  Oval,
  Parallelogram,
  Rectangle,
  Rhombus,
  Square,
  Accessibility,
  AccessibilityAudit,
  AccessibilityChecklists,
  AccessibilityColor,
  AccessibilityResources,
  AccessibilityText,
  Avatars,
  ActionBar,
  ActionList,
  Alert,
  BarList,
  ButtonRow,
  Button,
  Buttons,
  FloatingActionButton,
  Card,
  CardGroup,
  Checkbox,
  Colors,
  ColorsBrand,
  ColorsContent,
  ColorsIdentity,
  ColorsTagging,
  ColorsTheme,
  ColorsUtility,
  ContentSelection,
  DataCard,
  DataTable,
  Divider,
  Division,
  Elevation,
  Environment,
  Forms,
  FormModifier,
  FormSection,
  GroupAvatar,
  Headline,
  Histogram,
  Icon,
  IconEffects,
  IconFeaturesNavigation,
  IconFeedback,
  IconFiletypes,
  IconInteractions,
  IconLogos,
  IconMediaPlayback,
  IconPermissions,
  IconReactions,
  IconSports,
  IconTechnology,
  IconUIControls,
  IconUser,
  IconVideoFeatures,
  ItemTitle,
  KeyboardShortcut,
  Layout,
  Layering,
  Level,
  Legend,
  Link,
  LookupSelect,
  LookupMultiSelect,
  Modal,
  Module,
  Note,
  Notice,
  Notification,
  NumberList,
  OrgAvatar,
  Overlay,
  ParentCheckbox,
  Radio,
  SearchInput,
  SegmentedBar,
  SelectMark,
  Select,
  Selects,
  Shadows,
  SingleSelect,
  Space,
  Spinner,
  Subhead,
  TeamAvatar,
  Text,
  Textarea,
  TextInput,
  Themes,
  Toast,
  Toggle,
  Tooltip,
  Type,
  Typography,
  UserAvatar,
  VideoPlayer,
};
