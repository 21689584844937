import React from 'react';
import styles from '../../SvgIllustration.module.scss';

const IconUser = () => {
  return (
    <g className={'illustrationLayer'} fill="none" fillRule="evenodd">
      <path
        id="Mask"
        className={styles.neutral7}
        fill="currentColor"
        fillRule="nonzero"
        d="M139.821 84.9291C139.821 91.6561 145.273 97.1044 152 97.1044C158.727 97.1044 164.179 91.6561
        164.179 84.9291C164.179 78.2021 158.727 72.75 152 72.75C145.273 72.75 139.821 78.2021 139.821
        84.9291ZM165.884 76.8614C167.264 79.2329 168.05 81.988 168.05 84.9291C168.05 88.7809 166.694
        92.3187 164.431 95.0855C165.291 95.5079 166.105 95.9767 166.865 96.4844C167.256 96.5309 167.651
        96.5541 168.05 96.5541C173.557 96.5541 178.021 92.094 178.021 86.5915C178.021 81.089 173.557
        76.625 168.05 76.625C167.31 76.625 166.582 76.7064 165.884 76.8614ZM125.983 86.5915C125.983
        92.094 130.443 96.5541 135.946 96.5541C136.345 96.5541 136.744 96.5309 137.132 96.4844C137.895
        95.9767 138.709 95.5079 139.565 95.0855C137.302 92.3187 135.946 88.7809 135.946 84.9291C135.946
        81.988 136.736 79.2329 138.12 76.8614C137.418 76.7064 136.694 76.625 135.946 76.625C130.443
        76.625 125.983 81.089 125.983 86.5915ZM170.337 99.472C172.758 102.184 174.146 105.536 174.146
        109.287C174.146 110.039 174.13 110.783 174.111 111.5H180.884C181.958 111.5 182.868 110.655
        182.919 109.528C182.919 109.528 183 108.419 183 106.517C183 102.285 180.055 98.976 175.661
        97.2284C174.107 98.3444 172.297 99.1233 170.337 99.472ZM121 106.517C121 108.427 121.089
        109.531 121.089 109.531C121.147 110.62 122.046 111.5 123.131 111.5H129.893C129.87 110.783
        129.858 110.039 129.858 109.287C129.858 105.536 131.238 102.184 133.663 99.472C131.707
        99.1233 129.897 98.3444 128.335 97.2284C123.945 98.976 121 102.285 121 106.517ZM152
        100.914C148.532 100.914 145.32 99.8091 142.7 97.9336C137.329 100.065 133.729 104.11
        133.729 109.287C133.729 111.616 133.865 113.43 133.865 113.43C133.923 114.503 134.865
        115.375 135.911 115.375H168.112C169.182 115.375 170.096 114.515 170.147 113.426C170.147
        113.426 170.267 111.609 170.267 109.287C170.267 104.11 166.667 100.065 161.3 97.9336C158.68
        99.8091 155.468 100.914 152 100.914Z"
      />
    </g>
  );
};

export default IconUser;
