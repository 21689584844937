import React from 'react';
import styles from '../../SvgIllustration.module.scss';

const IconSports = () => {
  return (
    <g className={'illustrationLayer'} fill="none" fillRule="evenodd">
      <path
        id="Mask"
        className={styles.neutral7}
        fill="currentColor"
        fillRule="nonzero"
        d="M152 70.8125C138.089 70.8125 126.812 82.0893 126.812 96C126.812 109.911 138.089
        121.188 152 121.188C165.911 121.188 177.188 109.911 177.188 96C177.188 82.0893 165.911
        70.8125 152 70.8125ZM173.772 99.4242C172.125 101.276 169.072 102.676 167.31 103.268C166.24
        101.844 164.363 99.2843 163.079 97.5475C163.326 94.3291 164.001 87.67 164.125
        84.6821C165.466 84.0483 167.582 83.2745 169.829 83.0358C172.479 86.674 174.043
        91.1518 174.043 95.9918C174.035 97.1688 173.944 98.313 173.772 99.4242ZM150.963
        100.593C148.288 98.4776 143.217 94.2879 141.168 92.8392C141.546 90.7567 142.361
        85.8262 142.773 83.4474C145.769 82.1963 151.934 79.7187 154.346 78.8215C156.116
        79.9903 160.421 83.2663 162.264 84.6739C162.075 87.456 161.548 93.4154 161.359
        96.1729C159.301 97.0371 152.988 99.6711 150.963 100.593ZM152 73.9651C153.087
        73.9651 154.157 74.0474 155.21 74.2038C154.914 75.0598 154.519 76.2451 154.198
        77.167C151.169 78.2617 144.156 81.0933 141.711 82.0728C140.131 81.7106 137.554
        81.1344 135.924 80.9451C139.941 76.6484 145.654 73.9651 152 73.9651ZM130.204
        92.7487C130.936 93.9669 132.163 96 132.821 97.1441C132.303 99.3501 132.031 102.684
        131.941 105.12C130.673 102.338 129.965 99.2596 129.965 96.0082C129.965 94.897
        130.047 93.8105 130.204 92.7487ZM133.653 108.215C133.414 103.778 134.105 99.2925
        134.492 97.0289C136.023 96.2387 138.394 95.004 139.892 94.2303C142.435 96.2222
        148.535 100.898 150.576 102.396C150.576 104.807 150.576 108.643 150.601 110.619C148.123
        111.787 143.629 113.598 139.373 114.059C137.126 112.487 135.184 110.503 133.653
        108.215ZM141.415 115.335C144.073 114.932 148.839 114.026 151.794 112.133C155.029
        113.228 159.227 113.368 161.03 113.286C161.03 114.265 161.03 115.524 161.03 116.38C159.227
        117.451 155.292 118.043 152.313 118.043C148.469 118.035 144.551 117.055 141.415 115.335ZM162.824
        115.467C162.824 114.372 162.824 113.072 162.824 112.108C162.824 110.289 166.314 106.915 167.615
        105.104C170.347 104.264 172.306 103.178 173.492 102.297C171.796 107.993 166.421 112.726 162.824
        115.467Z"
      />
    </g>
  );
};

export default IconSports;
