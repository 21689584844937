/* eslint-disable max-len */
import React from 'react';
import styles from '../../SvgIllustration.module.scss';

const Histogram = () => {
  return (
    <g className={'illustrationLayer'} fill="none" fillRule="evenodd">
      <path
        d="M72 141C70.8954 141 70 140.105 70 139L70 95C70 92.7909 71.7909 91 74 91L87 91C89.2091 91 91 92.7909 91 95L91 139C91 140.105 90.1046 141 89 141L72 141Z"
        fill="currentColor"
        className={styles.neutral3}
      />
      <path
        d="M101 141C99.8954 141 99 140.105 99 139L99 55C99 52.7909 100.791 51 103 51L116 51C118.209 51 120 52.7909 120 55L120 139C120 140.105 119.105 141 118 141L101 141Z"
        fill="currentColor"
        className={styles.action}
      />
      <path
        d="M129 141C127.895 141 127 140.105 127 139L127 84C127 81.7909 128.791 80 131 80L144 80C146.209 80 148 81.7909 148 84L148 139C148 140.105 147.105 141 146 141L129 141Z"
        fill="currentColor"
        className={styles.neutral3}
      />
      <path
        d="M158 141C156.895 141 156 140.105 156 139L156 121C156 118.791 157.791 117 160 117L173 117C175.209 117 177 118.791 177 121L177 139C177 140.105 176.105 141 175 141L158 141Z"
        fill="currentColor"
        className={styles.neutral3}
      />
      <path
        d="M186 141C184.895 141 184 140.105 184 139L184 71C184 68.7909 185.791 67 188 67L201 67C203.209 67 205 68.7909 205 71L205 139C205 140.105 204.105 141 203 141L186 141Z"
        fill="currentColor"
        className={styles.neutral3}
      />
      <path
        d="M215 141C213.895 141 213 140.105 213 139L213 104C213 101.791 214.791 100 217 100L230 100C232.209 100 234 101.791 234 104L234 139C234 140.105 233.105 141 232 141L215 141Z"
        fill="currentColor"
        className={styles.neutral3}
      />
    </g>
  );
};

export default Histogram;
