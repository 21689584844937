import React from 'react';
import styles from '../../SvgIllustration.module.scss';

const IconEffects = () => {
  return (
    <g className={'illustrationLayer'} fill="none" fillRule="evenodd">
      <path
        id="Mask"
        className={styles.neutral7}
        fill="currentColor"
        fillRule="nonzero"
        d="M154.634 82.4323C153.018 82.4445 152.301 82.4533 151.405 82.477C150.818 82.4912
        150.191 82.5512 149.449 82.6581C149.084 82.6967 148.709 82.744 148.222 82.811C147.957 82.8478 147.957
        82.8478 147.692 82.8852C147.336 82.9352 147.178 82.9571 146.97 82.9851C146.293 83.066 145.601 83.157
        144.799 83.2689C143.603 83.4759 142.982 83.5764 142.271 83.6702C142.075 83.7003 141.871 83.7305
        141.643 83.7632C141.33 83.8076 141.33 83.8076 141.018 83.8511C140.342 83.9455 140.112 83.9781
        139.765 84.0304C139.46 84.0755 139.198 84.1136 138.638 84.1945C138.526 84.2107 138.526 84.2107
        138.414 84.2269C137.968 84.2918 137.787 84.3183 137.516 84.3586C136.864 84.4508 136.474 84.4974
        135.134 84.6492C134.952 84.6665 134.808 84.6817 134.47 84.7188C134.234 84.7448 134.234 84.7448
        133.995 84.7698C133.528 84.8174 133.165 84.8438 132.821 84.8509C131.981 84.8868 131.147 84.8654
        129.972 84.7951C129.532 84.7781 129.071 84.7194 128.601 84.6195C127.887 84.4381 127.372 84.0021
        127.168 83.3178C127.133 83.2057 127.093 83.1005 127.049 83.0071L127 82.7863V82.1228L127.048
        81.9045C127.098 81.7953 127.139 81.7014 127.23 81.491C127.389 81.122 127.483 80.9223 127.62
        80.6834C128.472 79.2895 129.63 78.1211 131.281 76.8661C132.457 75.9984 133.356 75.3448 134.231
        74.724C135.262 73.962 135.947 73.5634 136.751 73.3426C136.86 73.3028 136.972 73.2701 137.101
        73.2389C137.176 73.2205 137.243 73.2059 137.371 73.1785C137.458 73.1597 137.458 73.1597 137.659
        73.1268H138.778C138.949 73.147 138.949 73.147 139.038 73.1624C139.123 73.177 139.123 73.177
        139.216 73.1914C139.819 73.3025 140.152 73.4542 140.364 73.9032C140.591 74.3575 140.517 74.8251
        140.243 75.2505C139.816 76.0322 139.335 76.4981 138.175 77.4132C137.52 77.921 137.002 78.3004
        136.001 79.0179C135.775 79.1798 135.775 79.1798 135.549 79.3417C135.378 79.4649 135.225 79.5753
        135.082 79.6787C135.177 79.6641 135.284 79.6477 135.406 79.6291C136.266 79.4976 136.699 79.4297
        137.195 79.3457C137.583 79.2793 137.893 79.2251 138.587 79.1023C139.283 78.9793 139.594 78.9249
        139.982 78.8585C141.686 78.5585 142.442 78.4265 143.378 78.2672C144.432 78.0778 145.482 77.9022
        146.499 77.7457C147.884 77.5289 148.499 77.4378 149.264 77.3438C149.495 77.3143 149.495 77.3143
        149.727 77.2843C150.28 77.2123 150.484 77.1864 150.772 77.1524C151.349 77.0843 151.835 77.0389
        152.304 77.0129C152.848 76.98 153.41 76.9633 154.12 76.955C154.354 76.9523 154.541 76.9509
        154.96 76.9482C155.435 76.9453 155.647 76.9436 155.917 76.94C156.139 76.9267 156.191 76.924
        156.205 76.927C157.047 76.8357 157.72 76.8731 158.366 77.0939C159.556 77.4845 160.469 78.265
        161.016 79.378C161.699 80.7951 161.692 82.2989 161.038 83.816C160.566 84.8644 159.9 85.7735
        158.854 86.9111C157.565 88.269 156.067 89.4949 154.271 90.7068C152.532 91.9048 151.115 92.8535
        147.967 94.9431C147.705 95.1167 147.528 95.2341 147.363 95.3436C147.41 95.3233 147.457 95.3031
        147.504 95.2829C151.344 93.5862 154.853 92.2273 158.277 91.1459C162.338 89.8547 165.374 89.1538
        168.467 88.9246C169.793 88.8219 170.881 88.7818 171.944 88.8113C173.438 88.855 174.681 89.58
        175.591 90.8677C176.294 91.8477 176.579 92.9677 176.478 94.2227C176.414 94.9743 176.222 95.6304
        175.869 96.2492C175.332 97.2006 174.586 97.9962 173.519 98.8556C172.829 99.4315 172.181 99.9562
        171.068 100.846C170.822 101.043 170.822 101.043 170.576 101.239C170.209 101.533 170.058 101.654
        169.841 101.828C169.316 102.247 168.761 102.684 168.089 103.208C167.696 103.515 166.322 104.583
        166.344 104.565C166.085 104.769 165.839 104.961 165.337 105.354C164.432 106.061 163.906 106.477
        163.346 106.933C162.134 107.921 161.325 108.599 160.541 109.293C160.215 109.58 160.215 109.58
        159.892 109.864C159.051 110.603 158.525 111.074 157.994 111.569C157.363 112.164 156.705 112.823
        155.995 113.569C155.508 114.066 155.062 114.551 154.324 115.374C154.146 115.573 154.121 115.601
        153.985 115.752C154.709 115.654 155.432 115.566 156.128 115.49C157.178 115.382 157.64 115.332
        158.2 115.26C158.561 115.213 158.849 115.177 159.498 115.096C160.144 115.015 160.432 114.978
        160.795 114.931C161.107 114.894 161.356 114.868 161.914 114.814C162.465 114.761 162.708 114.736
        163.013 114.699C163.255 114.672 163.469 114.646 163.878 114.594C164.637 114.499 164.929 114.464
        165.318 114.427C165.626 114.394 165.953 114.362 166.335 114.328C166.597 114.305 166.844 114.284
        167.345 114.242C167.566 114.222 167.742 114.206 168.142 114.167C168.222 114.159 168.222 114.159
        168.302 114.152C168.957 114.089 169.343 114.055 169.761 114.026C170.09 114 170.432 113.984
        70.853 113.973C171.019 113.969 171.153 113.967 171.449 113.962C171.685 113.957 171.685 113.957
        171.898 113.953C172.806 113.899 173.505 114.066 174.093 114.59C174.144 114.636 174.191 114.673
        174.307 114.759C174.449 114.865 174.514 114.915 174.582 114.977C174.717 115.089 174.717 115.089
        174.855 115.202C176.09 116.215 176.587 116.74 177 117.84V118.288L176.889 118.61C176.811 118.712
        176.811 118.712 176.788 118.742C176.617 118.967 176.514 119.086 176.357 119.215C176.258 119.294
        176.171 119.371 176.007 119.52C175.516 119.964 175.271 120.153 174.894 120.331C173.716 120.861
        172.449 121.211 171.006 121.433C170.418 121.529 169.913 121.598 168.944 121.722C168.299 121.818
        167.943 121.871 167.656 121.913C167.167 121.985 166.759 122.044 166.369 122.098C166.162 122.128
        165.945 122.158 165.699 122.189C165.517 122.212 165.366 122.231 165.036 122.272C164.4 122.35
        164.096 122.389 163.736 122.442C163.32 122.501 163.077 122.536 162.199 122.666C161.482 122.771
        161.084 122.829 160.64 122.89C160.195 122.95 159.854 122.993 159.038 123.091C158.26 123.185
        157.896 123.23 157.456 123.29C156.943 123.357 156.498 123.419 155.637 123.541C154.661 123.68
        154.318 123.728 153.819 123.794C153.321 123.86 152.797 123.926 152.187 124C151.764
        124.052 151.37 124.098 150.586 124.19C150.485 124.208 150.403 124.221 150.224 124.248C150.049
        124.275 149.975 124.286 149.794 124.31L148.202 124.304C148.085 124.285 148.003 124.271 147.814
        124.238C147.63 124.205 147.63 124.205 147.477 124.177C147.369 124.161 147.292 124.151 147.102
        124.129C146.739 124.086 146.57 124.061 146.343 124.003C144.904 123.598 143.885 122.691 143.324
        121.31C143.024 120.588 142.882 119.868 142.925 119.178C142.965 118.169 143.298 117.212 143.899
        116.202C144.942 114.415 146.06 112.83 147.303 111.408C148.359 110.179 149.362 109.083 150.36
        108.078C150.974 107.438 151.514 106.924 152.63 105.899L152.776 105.764C153.046 105.515 153.161
        105.41 153.32 105.262C154.262 104.407 154.99 103.77 155.712 103.176C156.563 102.472 157.402 101.803
        158.676 100.806C159.426 100.23 160.028 99.7681 161.383 98.7278C162.213 98.0901 162.761 97.6698
        163.234 97.3063C162.623 97.4442 162.241 97.5376 161.81 97.6555C158.672 98.5092 155.459 99.6268
        151.772 101.105C150.948 101.438 150.104 101.786 149.001 102.246C148.665 102.386 148.365 102.511 147.729
        102.778C146.382 103.342 145.649 103.646 144.83 103.981C143.158 104.677 141.896 105.197 140.662
        105.696C139.862 106.007 137.047 107.131 136.432 107.371C136.273 107.433 136.273 107.433 136.112
        107.495C134.546 108.101 132.977 108.192 131.454 107.723C129.645 107.167 128.771 105.426 129.354
        103.66C129.565 103.033 129.843 102.498 130.395 101.57C131.106 100.416 131.992 99.3222 133.045
        98.2691C133.835 97.4785 134.421 96.9203 135.012 96.4073C136.108 95.4456 136.61 95.0313 137.294
        94.5683C138.44 93.7945 139.418 93.1632 141.449 91.8723C141.594 91.7801 141.594 91.7801 141.739
        91.688C142.297 91.3332 142.648 91.1097 143.013 90.8758C143.435 90.6061 143.877 90.3256 144.367
        90.0153C144.884 89.6879 145.361 89.3865 146.354 88.7602C148.548 87.376 149.76 86.6029 151.089
        85.7318C153.02 84.4581 154.157 83.5659 155.145 82.4284C155 82.4295 154.834 82.4308 154.634
        82.4323Z"
      />
    </g>
  );
};

export default IconEffects;
