import React from 'react';
import styles from '../../SvgIllustration.module.scss';

const VideoPlayer = () => {
  return (
    <g className={'illustrationLayer'} fill="none" fillRule="evenodd">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M46 31C46 26.5817 49.5817 23 54 23H250C254.418 23 258 26.5817 258 31V161C258 165.418 254.418 169 250 169H54C49.5817 169 46 165.418 46 161V31Z"
        className={styles.neutral10}
        fill="currentColor"
      />
      <rect
        x="51"
        y="137"
        width="201"
        height="26"
        rx="4"
        className={styles.neutral8}
        fill="currentColor"
      />
      <path
        d="M142 74.868C142 74.671 142.058 74.479 142.168 74.314C142.361 74.025 142.677 73.868 143 73.868C143.191 73.868 143.384 73.923 143.555 74.037L163.252 87.168C163.362 87.241 163.456 87.336 163.529 87.446C163.835 87.905 163.711 88.526 163.252 88.832L143.555 101.964C143.39 102.073 143.197 102.132 143 102.132C142.448 102.132 142 101.684 142 101.131V74.868Z"
        className={styles.neutral5}
        fill="currentColor"
      />
      <path
        d="M73 150C73 152.761 70.7614 155 68 155C65.2386 155 63 152.761 63 150C63 147.239 65.2386 145 68 145C70.7614 145 73 147.239 73 150Z"
        className={styles.neutral5}
        fill="currentColor"
      />
      <path
        d="M109 150C109 152.761 106.761 155 104 155C101.239 155 99 152.761 99 150C99 147.239 101.239 145 104 145C106.761 145 109 147.239 109 150Z"
        className={styles.neutral5}
        fill="currentColor"
      />
      <path
        d="M216 155C218.761 155 221 152.761 221 150C221 147.239 218.761 145 216 145C213.239 145 211 147.239 211 150C211 152.761 213.239 155 216 155Z"
        className={styles.neutral5}
        fill="currentColor"
      />
      <path
        d="M91 150C91 152.761 88.7614 155 86 155C83.2386 155 81 152.761 81 150C81 147.239 83.2386 145 86 145C88.7614 145 91 147.239 91 150Z"
        className={styles.neutral5}
        fill="currentColor"
      />
      <path
        d="M122 155C124.761 155 127 152.761 127 150C127 147.239 124.761 145 122 145C119.239 145 117 147.239 117 150C117 152.761 119.239 155 122 155Z"
        className={styles.neutral5}
        fill="currentColor"
      />
      <path
        d="M239 150C239 152.761 236.761 155 234 155C231.239 155 229 152.761 229 150C229 147.239 231.239 145 234 145C236.761 145 239 147.239 239 150Z"
        className={styles.neutral5}
        fill="currentColor"
      />
    </g>
  );
};

export default VideoPlayer;
