import React from 'react';
import styles from '../../SvgIllustration.module.scss';

const IconFiletype = () => {
  return (
    <g className={'illustrationLayer'} fill="none" fillRule="evenodd">
      <path
        id="Mask"
        className={styles.neutral7}
        fill="currentColor"
        fillRule="nonzero"
        d="M172.961 81.7823C173.82 82.6035 174.5 84.2167 174.5 85.4025V119.342C174.5 121.362
        172.783 123 170.673 123H133.327C131.214 123 129.5 121.362 129.5 119.342V72.6585C129.5
        70.638 131.217 69 133.327 69H157.441C158.653 69 160.278 69.6592 161.128 70.4715L172.961
        81.7823ZM149.75 72.978C149.75 73.2593 149.997 73.5 150.301 73.5H153.114C153.769 73.5
        154.25 72.996 154.25 72.375C154.25 71.772 153.742 71.25 153.114 71.25H150.301C149.993
        71.25 149.75 71.484 149.75 71.772V72.978ZM150.886 78H153.699C154.007 78 154.25 77.766
        154.25 77.478V76.272C154.25 75.9907 154.003 75.75 153.699 75.75H150.886C150.231 75.75
        149.75 76.254 149.75 76.875C149.75 77.478 150.258 78 150.886 78ZM149.75 81.978C149.75
        82.2593 149.997 82.5 150.301 82.5H153.114C153.769 82.5 154.25 81.996 154.25 81.375C154.25
        80.772 153.742 80.25 153.114 80.25H150.301C149.993 80.25 149.75 80.484 149.75
        80.772V81.978ZM150.886 87H153.699C154.007 87 154.25 86.766 154.25 86.478V85.272C154.25
        84.9907 154.003 84.75 153.699 84.75H150.886C150.231 84.75 149.75 85.254 149.75 85.875C149.75
        86.478 150.258 87 150.886 87ZM150.886 96H153.699C154.007 96 154.25 95.766 154.25
        95.478V94.272C154.25 93.9907 154.003 93.75 153.699 93.75H150.886C150.231 93.75 149.75 94.254
        149.75 94.875C149.75 95.478 150.258 96 150.886 96ZM149.75 90.978C149.75 91.2593 149.997 91.5
        150.301 91.5H153.114C153.769 91.5 154.25 90.996 154.25 90.375C154.25 89.772 153.742 89.25
        153.114 89.25H150.301C149.993 89.25 149.75 89.484 149.75 89.772V90.978ZM149.75 107.25C149.75
        108.501 150.758 109.5 152 109.5C153.251 109.5 154.25 108.492 154.25 107.25V100.5C154.25 99.249
        153.242 98.25 152 98.25C150.749 98.25 149.75 99.258 149.75 100.5V107.25ZM152 106.125C152.621
        106.125 153.125 106.647 153.125 107.25C153.125 107.871 152.603 108.375 152 108.375C151.379
        108.375 150.875 107.853 150.875 107.25C150.875 106.629 151.397 106.125 152 106.125Z"
      />
    </g>
  );
};

export default IconFiletype;
