import React from 'react';
import styles from '../../SvgIllustration.module.scss';

const IconTechnology = () => {
  return (
    <g className={'illustrationLayer'} fill="none" fillRule="evenodd">
      <path
        className={styles.neutral7}
        fill="currentColor"
        fillRule="nonzero"
        path
        d="M152 70.5625C165.812 70.5625 178.136 76.9358 186.19 86.9021L180.987 91.5035C174.209
        82.9711 163.743 77.5 152 77.5C140.29 77.5 129.85 82.9399 123.071 91.4304L117.868 86.8295C125.924
        76.9049 138.221 70.5625 152 70.5625Z"
      />
      <path
        className={styles.neutral7}
        fill="currentColor"
        fillRule="nonzero"
        d="M170.57 100.716L175.781 96.1074C170.282 89.0079 161.675 84.4375 152 84.4375C142.358 84.4375
        133.777 88.9762 128.276 96.0336L133.486 100.641C137.704 95.0152 144.427 91.375 152 91.375C159.606
        91.375 166.355 95.0475 170.57 100.716Z"
      />
      <path
        className={styles.neutral7}
        fill="currentColor"
        fillRule="nonzero"
        d="M165.346 105.336L160.08 109.993C158.498 107.163 155.472 105.25 152 105.25C148.562 105.25
        145.561 107.126 143.967 109.91L138.708 105.259C141.632 101.06 146.495 98.3125 152 98.3125C157.538
        98.3125 162.427 101.094 165.346 105.336Z"
      />
      <path
        className={styles.neutral7}
        fill="currentColor"
        fillRule="nonzero"
        d="M156.047 116.812C156.047 119.048 154.235 120.859 152 120.859C149.765 120.859 147.953 119.048
        147.953 116.812C147.953 114.577 149.765 112.766 152 112.766C154.235 112.766 156.047 114.577 156.047 116.812Z"
      />
      />
    </g>
  );
};

export default IconTechnology;
