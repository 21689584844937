import React from 'react';
import styles from '../../SvgIllustration.module.scss';

const IconUIControls = () => {
  return (
    <g className={'illustrationLayer'} fill="none" fillRule="evenodd">
      <path
        id="Mask"
        className={styles.neutral7}
        fill="currentColor"
        fillRule="nonzero"
        d="M142 68.5C144.761 68.5 147 70.7386 147 73.5C147 76.2614 144.761 78.5 142 78.5C139.239 78.5
        137 76.2614 137 73.5C137 70.7386 139.239 68.5 142 68.5ZM162 68.5C164.761 68.5 167 70.7386 167
        73.5C167 76.2614 164.761 78.5 162 78.5C159.239 78.5 157 76.2614 157 73.5C157 70.7386 159.239
        68.5 162 68.5ZM142 83.5C144.761 83.5 147 85.7386 147 88.5C147 91.2614 144.761 93.5 142 93.5C139.239
        93.5 137 91.2614 137 88.5C137 85.7386 139.239 83.5 142 83.5ZM162 83.5C164.761 83.5 167 85.7386 167
        88.5C167 91.2614 164.761 93.5 162 93.5C159.239 93.5 157 91.2614 157 88.5C157 85.7386 159.239 83.5 162
        83.5ZM142 98.5C144.761 98.5 147 100.739 147 103.5C147 106.261 144.761 108.5 142 108.5C139.239 108.5
        137 106.261 137 103.5C137 100.739 139.239 98.5 142 98.5ZM162 98.5C164.761 98.5 167 100.739 167
        103.5C167 106.261 164.761 108.5 162 108.5C159.239 108.5 157 106.261 157 103.5C157 100.739 159.239 98.5
        162 98.5ZM142 113.5C144.761 113.5 147 115.739 147 118.5C147 121.261 144.761 123.5 142 123.5C139.239
        123.5 137 121.261 137 118.5C137 115.739 139.239 113.5 142 113.5ZM162 113.5C164.761 113.5 167 115.739
        167 118.5C167 121.261 164.761 123.5 162 123.5C159.239 123.5 157 121.261 157 118.5C157 115.739 159.239
        113.5 162 113.5Z"
      />
    </g>
  );
};

export default IconUIControls;
