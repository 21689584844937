/* eslint-disable max-len */
import React from 'react';
import styles from '../../SvgIllustration.module.scss';

const CardGroup = () => {
  return (
    <g className={'illustrationLayer'} fill="none" fillRule="evenodd">
      <rect
        className={styles.neutral1}
        x="37"
        y="80"
        width="230"
        height="77"
        rx="8"
        fill="currentColor"
      />
      <rect
        className={styles.neutral8}
        x="50"
        y="91"
        width="135"
        height="12"
        rx="6"
        fill="currentColor"
      />
      <rect
        className={styles.neutral2}
        x="42"
        y="117"
        width="220"
        height="35"
        rx="8"
        fill="currentColor"
      />
      <path
        className={styles.neutral8}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M251 96.0938L246.279 100.625C245.758 101.125 244.913 101.125 244.391 100.625C243.87 100.124 243.87 99.3128 244.391 98.8123L250.056 93.3753C250.577 92.8749 251.423 92.8749 251.944 93.3753L257.609 98.8123C258.13 99.3128 258.13 100.124 257.609 100.625C257.087 101.125 256.242 101.125 255.721 100.625L251 96.0938Z"
        fill="currentColor"
      />
      <rect
        className={styles.neutral1}
        x="37"
        y="36"
        width="230"
        height="38"
        rx="8"
        fill="currentColor"
      />
      <rect
        className={styles.neutral5}
        x="50"
        y="49"
        width="135"
        height="12"
        rx="6"
        fill="currentColor"
      />
      <path
        className={styles.neutral5}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M251 56.9062L255.721 52.3753C256.242 51.8749 257.087 51.8749 257.609 52.3753C258.13 52.8758 258.13 53.6872 257.609 54.1877L251.944 59.6247C251.423 60.1251 250.577 60.1251 250.056 59.6247L244.391 54.1877C243.87 53.6872 243.87 52.8758 244.391 52.3753C244.913 51.8749 245.758 51.8749 246.279 52.3753L251 56.9062Z"
        fill="currentColor"
      />
    </g>
  );
};

export default CardGroup;
