import React from 'react';
import styles from '../../SvgIllustration.module.scss';

const Shadows = () => {
  return (
    <g className={'illustrationLayer'} fill="none" fillRule="evenodd">
      <circle
        className={styles.black}
        cx="69"
        cy="97"
        r="42"
        fill="currentColor"
        fillOpacity=".03"
      />
      <circle
        className={styles.white}
        cx="69"
        cy="97"
        r="40"
        fill="currentColor"
      />
      <circle
        className={styles.black}
        cx="111.333"
        cy="97"
        r="45"
        fill="currentColor"
        fillOpacity=".03"
      />
      <circle
        className={styles.black}
        cx="111.333"
        cy="97"
        r="41"
        fill="currentColor"
        fillOpacity=".03"
      />
      <circle
        className={styles.white}
        cx="111.333"
        cy="97"
        r="40"
        fill="currentColor"
      />
      <circle
        className={styles.black}
        cx="161.667"
        cy="97"
        r="50"
        fill="currentColor"
        fillOpacity=".03"
      />
      <circle
        className={styles.black}
        cx="161.667"
        cy="97"
        r="45"
        fill="currentColor"
        fillOpacity=".03"
      />
      <circle
        className={styles.black}
        cx="161.667"
        cy="97"
        r="41"
        fill="currentColor"
        fillOpacity=".03"
      />
      <circle
        className={styles.white}
        cx="161.667"
        cy="97"
        r="40"
        fill="currentColor"
      />
      <circle
        className={styles.black}
        cx="222"
        cy="96"
        r="55"
        fill="currentColor"
        fillOpacity=".03"
      />
      <circle
        className={styles.black}
        cx="222"
        cy="97"
        r="50"
        fill="currentColor"
        fillOpacity=".03"
      />
      <circle
        className={styles.black}
        cx="222"
        cy="97"
        r="45"
        fill="currentColor"
        fillOpacity=".03"
      />
      <circle
        className={styles.black}
        cx="222"
        cy="97"
        r="41"
        fill="currentColor"
        fillOpacity=".03"
      />
      <circle
        className={styles.white}
        cx="222"
        cy="97"
        r="40"
        fill="currentColor"
      />
    </g>
  );
};

export default Shadows;
