import React from 'react';
import styles from '../../SvgIllustration.module.scss';

const IconInteractions = () => {
  return (
    <g className={'illustrationLayer'} fill="none" fillRule="evenodd">
      <path
        id="Mask"
        className={styles.neutral7}
        fill="currentColor"
        fillRule="nonzero"
        d="M128.75 76.625C126.611 76.625 124.875 78.3591 124.875 80.5V107.625C124.875 109.764
        126.611 111.5 128.75 111.5H148.842L165.915 122.881C166.359 123.177 166.959 123.057
        167.256 122.613C167.401 122.395 167.454 122.129 167.399 121.873L165.202 111.5H175.25C177.389
        111.5 179.125 109.764 179.125 107.625V80.5C179.125 78.3591 177.389 76.625 175.25 76.625H128.75Z"
      />
    </g>
  );
};

export default IconInteractions;
