import React from 'react';
import styles from '../../SvgIllustration.module.scss';

const Layout = () => {
  return (
    <g className={'illustrationLayer'} fill="none" fillRule="evenodd">
      <path
        className={styles.neutral2}
        fill="currentColor"
        d="M74 46C74 41.5817 77.5817 38 82 38H104C108.418 38 112 41.5817 112 46V146C112
        150.418 108.418 154 104 154H82C77.5817 154 74 150.418 74 146V46Z"
      />
      <path
        d="M227 38C229.209 38 231 39.7909 231 42V52C231 54.2091 229.209 56 227 56L124
        56C121.791 56 120 54.2091 120 52L120 42C120 39.7909 121.791 38 124 38L227 38Z"
        className={styles.neutral5}
        fill="currentColor"
      />
      <path
        d="M227 100C229.209 100 231 101.791 231 104V113C231 115.209 229.209 117 227
        117H124C121.791 117 120 115.209 120 113L120 104C120 101.791 121.791 100 124 100L227 100Z"
        className={styles.neutral5}
        fill="currentColor"
      />
      <path
        d="M144 63C148.418 63 152 66.5817 152 71V84C152 88.4183 148.418 92 144 92H128C123.582
        92 120 88.4183 120 84L120 71C120 66.5817 123.582 63 128 63L144 63Z"
        className={styles.neutral3}
        fill="currentColor"
      />
      <path
        d="M184 63C188.418 63 192 66.5817 192 71V84C192 88.4183 188.418 92 184 92H168C163.582
        92 160 88.4183 160 84V71C160 66.5817 163.582 63 168 63L184 63Z"
        className={styles.neutral4}
        fill="currentColor"
      />
      <path
        d="M223 63C227.418 63 231 66.5817 231 71V84C231 88.4183 227.418 92 223 92H207C202.582
        92 199 88.4183 199 84V71C199 66.5817 202.582 63 207 63L223 63Z"
        className={styles.neutral3}
        fill="currentColor"
      />
      <path
        d="M144 125C148.418 125 152 128.582 152 133V146C152 150.418 148.418 154 144
        154H128C123.582 154 120 150.418 120 146L120 133C120 128.582 123.582 125 128 125H144Z"
        className={styles.neutral3}
        fill="currentColor"
      />
      <path
        d="M184 125C188.418 125 192 128.582 192 133V146C192 150.418 188.418 154 184
        154H168C163.582 154 160 150.418 160 146V133C160 128.582 163.582 125 168 125H184Z"
        className={styles.neutral4}
        fill="currentColor"
      />
      <path
        d="M223 125C227.418 125 231 128.582 231 133V146C231 150.418 227.418 154 223
        154H207C202.582 154 199 150.418 199 146V133C199 128.582 202.582 125 207 125H223Z"
        className={styles.neutral3}
        fill="currentColor"
      />
    </g>
  );
};

export default Layout;
