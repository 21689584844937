import React from 'react';
import styles from '../../SvgIllustration.module.scss';

const IconReactions = () => {
  return (
    <g className={'illustrationLayer'} fill="none" fillRule="evenodd">
      <path
        className={styles.neutral7}
        fill="currentColor"
        fillRule="nonzero"
        d="M135.536 124.357L174.263 112.02L137.264 128.784C136.061 129.331 134.638 128.803
        134.089 127.61C133.538 126.414 134.07 125.004 135.272 124.458C135.355 124.421 135.452
        124.385 135.536 124.357Z"
      />
      <path
        className={styles.neutral7}
        fill="currentColor"
        fillRule="nonzero"
        d="M129.878 113.615L177 103.242L131.146 118.195C129.889 118.604 128.535 117.924 128.12
        116.677C127.708 115.43 128.392 114.087 129.649 113.677C129.725 113.654 129.802 113.632
        129.878 113.615Z"
      />
      <path
        className={styles.neutral7}
        fill="currentColor"
        fillRule="nonzero"
        d="M169.055 73.4765C167.966 74.43 165.979 78.2594 165.1 83.968C164.292 89.2159 164.914
        92.7066 165.524 93.8102C166.612 92.8566 168.599 89.0273 169.478 83.3187C170.286 78.0708
        169.664 74.58 169.055 73.4765ZM165.366 98.2867C164.262 98.2867 163.221 97.7852 162.433
        96.8767C160.12 94.2023 160.083 88.1209 160.822 83.3187C161.64 78.0022 164.327 69 169.212
        69C170.316 69 171.357 69.5014 172.143 70.41C174.458 73.0822 174.495 79.1658 173.756
        83.968C172.938 89.2866 170.249 98.2867 165.366 98.2867Z"
      />
      <path
        className={styles.neutral7}
        fill="currentColor"
        fillRule="nonzero"
        d="M151.777 75.6193C150.689 76.5729 148.702 80.4022 147.823 86.1109C147.015 91.3588 147.637
        94.8495 148.246 95.9531C149.335 94.9995 151.321 91.1702 152.2 85.4616C153.008 80.2137 152.386
        76.7229 151.777 75.6193ZM148.088 100.43C146.985 100.43 145.944 99.9281 145.156 99.0195C142.843
        96.3452 142.806 90.2637 143.544 85.4616C144.363 80.1451 147.05 71.1429 151.935 71.1429C153.038
        71.1429 154.079 71.6443 154.868 72.5529C157.181 75.2251 157.217 81.3087 156.479 86.1109C155.66
        91.4295 152.971 100.43 148.088 100.43Z"
      />
      <path
        className={styles.neutral7}
        fill="currentColor"
        fillRule="nonzero"
        d="M133.646 104.417C133.529 104.417 133.41 104.409 133.289 104.387C132.11 104.195 131.313
        103.089 131.508 101.919L134.746 82.4929C134.826 82.0096 134.265 81.6814 133.878 81.9854L132.017
        83.4473C131.067 84.1651 129.709 83.983 128.985 83.0423C128.259 82.0994 128.441 80.7515 129.391
        80.0337L139.939 71.9727C140.328 71.6759 140.882 72.0047 140.802 72.4845L135.777 102.62C135.602
        103.672 134.684 104.417 133.646 104.417Z"
      />
    </g>
  );
};

export default IconReactions;
