/* eslint-disable max-len */
import React from 'react';
import styles from '../../SvgIllustration.module.scss';

const Module = () => {
  return (
    <g className={'illustrationLayer'} fill="none" fillRule="evenodd">
      <rect
        className={styles.neutral2}
        x="32"
        y="36"
        width="240"
        height="121"
        rx="12"
        fill="currentColor"
      />
      <path
        className={styles.neutral8}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M257.609 50C258.13 50.5005 258.13 51.3119 257.609 51.8123L251.944 57.2493C251.423 57.7498 250.577 57.7498 250.056 57.2493L244.391 51.8123C243.87 51.3119 243.87 50.5005 244.391 50H257.609Z"
        fill="currentColor"
      />
      <rect
        className={styles.neutral8}
        x="50"
        y="48"
        width="158"
        height="12"
        rx="6"
        fill="currentColor"
      />
      <rect
        className={styles.neutral1}
        x="37"
        y="75"
        width="230"
        height="77"
        rx="8"
        fill="currentColor"
      />
      <rect
        className={styles.neutral5}
        x="50"
        y="86"
        width="135"
        height="12"
        rx="6"
        fill="currentColor"
      />
      <rect
        className={styles.neutral2}
        x="42"
        y="112"
        width="220"
        height="35"
        rx="8"
        fill="currentColor"
      />
      <path
        className={styles.neutral5}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M245 129C245 130.658 246.344 132 248 132C249.656 132 251 130.658 251 129C251 127.342 249.656 126 248 126C246.344 126 245 127.342 245 129ZM236 129C236 130.658 237.342 132 239 132C240.658 132 242 130.658 242 129C242 127.342 240.658 126 239 126C237.342 126 236 127.342 236 129ZM227 129C227 130.658 228.342 132 230 132C231.658 132 233 130.658 233 129C233 127.342 231.658 126 230 126C228.342 126 227 127.342 227 129Z"
        fill="currentColor"
      />
      <rect
        className={styles.neutral5}
        x="50"
        y="123"
        width="98"
        height="12"
        rx="6"
        fill="currentColor"
      />
      <path
        className={styles.neutral5}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M251 91.0938L246.279 95.6247C245.758 96.1251 244.913 96.1251 244.391 95.6247C243.87 95.1242 243.87 94.3128 244.391 93.8123L250.056 88.3753C250.577 87.8749 251.423 87.8749 251.944 88.3753L257.609 93.8123C258.13 94.3128 258.13 95.1242 257.609 95.6247C257.087 96.1251 256.242 96.1251 255.721 95.6247L251 91.0938Z"
        fill="currentColor"
      />
    </g>
  );
};

export default Module;
