import React from 'react';
import styles from '../../SvgIllustration.module.scss';

const IconMediaPlayback = () => {
  return (
    <g className={'illustrationLayer'} fill="none" fillRule="evenodd">
      <path
        id="Mask"
        className={styles.neutral7}
        fill="currentColor"
        fillRule="nonzero"
        d="M151.963 72.4235L138.121 83.1919C137.76 83.4724 137.315 83.625 136.855 83.625H125.188C124.047
        83.625 123.125 84.549 123.125 85.6875V106.312C123.125 107.453 124.047 108.375 125.188
        108.375H136.855C137.315 108.375 137.76 108.528 138.121 108.81L151.963 119.576C153.089
        120.449 154.708 120.247 155.581 119.125C155.935 118.671 156.125 118.114 156.125
        117.541V74.4592C156.125 73.0361 154.97 71.8811 153.547 71.8811C152.974 71.8811 152.417 72.0729
        151.963 72.4235ZM167.939 79.3968L170.705 75.0429C177.431 79.5804 181.855 87.2756 181.855
        95.9999C181.855 104.724 177.431 112.419 170.705 116.957L167.939 112.603C173.229 108.979
        176.701 102.895 176.701 95.9999C176.701 89.105 173.229 83.0206 167.939 79.3968ZM161.848
        88.9854L164.618 84.6232C168.184 87.1477 170.511 91.3016 170.511 96C170.511 100.698 168.184
        104.854 164.618 107.377L161.848 103.015C163.977 101.414 165.357 98.8689 165.357 96C165.357
        93.131 163.977 90.5859 161.848 88.9854Z"
      />
    </g>
  );
};

export default IconMediaPlayback;
