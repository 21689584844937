import React from 'react';
import styles from '../../SvgIllustration.module.scss';
/* eslint-disable */
const Accessibility = () => {
  return (
    <g className={'illustrationLayer'} fill="none" fillRule="evenodd">
      <rect width="304" height="192" />
      <circle id="Ellipse 2" cx="152" cy="96" r="56" fill="currentColor" className={styles.action} />
      <path
        id="Ellipse 1"
        d="M160.025 72.8413C160.025 77.3133 156.4 80.9385 151.928 80.9385C147.456 80.9385 143.831 77.3133 143.831 72.8413C143.831 68.3694 147.456 64.7442 151.928 64.7442C156.4 64.7442 160.025 68.3694 160.025 72.8413Z"
        fill="currentColor"
        className={styles.white}
      />
      <path
        id="Vector 1 (Stroke)"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M176.206 83.0098C176.34 84.2805 175.419 85.419 174.148 85.5528L173.996 85.5688C163.15 86.7104 157.544 87.3006 151.783 87.3006C146.028 87.3006 140.139 86.7116 128.721 85.5698L128.563 85.554C127.291 85.4268 126.364 84.2931 126.491 83.0218C126.618 81.7504 127.752 80.8229 129.023 80.95C140.636 82.1113 146.286 82.6736 151.783 82.6736C157.274 82.6736 162.631 82.1125 173.663 80.9512C174.934 80.8175 176.073 81.7391 176.206 83.0098Z"
        fill="currentColor"
        className={styles.white}
      />
      <path
        id="Vector 2 (Stroke)"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M152.286 83.2779C153.55 83.462 154.426 84.6362 154.242 85.9006L154.218 86.0688C152.816 95.6967 152.086 100.714 150.746 105.714C149.408 110.707 147.468 115.661 143.736 125.193L143.671 125.358C143.205 126.547 141.863 127.134 140.674 126.668C139.484 126.202 138.897 124.86 139.363 123.671C143.175 113.936 145.013 109.231 146.277 104.517C147.538 99.8086 148.232 95.069 149.663 85.234C149.848 83.9696 151.022 83.0939 152.286 83.2779Z"
        fill="currentColor"
        className={styles.white}
      />
      <path
        id="Vector 3 (Stroke)"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M149.8 83.2761C148.536 83.4602 147.66 84.6344 147.844 85.8987L147.869 86.067C149.27 95.6949 150.001 100.712 151.34 105.712C152.678 110.705 154.618 115.66 158.35 125.191L158.415 125.356C158.881 126.546 160.223 127.132 161.413 126.667C162.602 126.201 163.189 124.858 162.723 123.669C158.912 113.934 157.073 109.23 155.81 104.515C154.548 99.8068 153.855 95.0672 152.423 85.2322C152.239 83.9678 151.064 83.0921 149.8 83.2761Z"
        fill="currentColor"
        className={styles.white}
      />
      <path
        id="Vector 4 (Stroke)"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M147.301 102.917V85.5655H155.398V102.917H147.301Z"
        fill="currentColor"
        className={styles.white}
      />
    </g>
  );
};

export default Accessibility;
