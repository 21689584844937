/* eslint-disable max-len */
import React from 'react';
import styles from '../../SvgIllustration.module.scss';

const DataCard = () => {
  return (
    <g className={'illustrationLayer'} fill="none" fillRule="evenodd">
      <rect
        x="42"
        y="35"
        width="220"
        height="35"
        rx="8"
        fill="currentColor"
        className={styles.neutral1}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M245 52C245 53.6575 246.344 55 248 55C249.656 55 251 53.6575 251 52C251 50.3425 249.656 49 248 49C246.344 49 245 50.3425 245 52ZM236 52C236 53.6575 237.342 55 239 55C240.658 55 242 53.6575 242 52C242 50.3425 240.658 49 239 49C237.342 49 236 50.3425 236 52ZM227 52C227 53.6575 228.342 55 230 55C231.658 55 233 53.6575 233 52C233 50.3425 231.658 49 230 49C228.342 49 227 50.3425 227 52Z"
        fill="currentColor"
        className={styles.neutral3}
      />
      <rect x="53" y="46" width="95" height="12" rx="6" fill="currentColor" />
      <rect
        x="42"
        y="75"
        width="220"
        height="81"
        rx="8"
        fill="currentColor"
        className={styles.neutral1}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M245 92C245 93.6575 246.344 95 248 95C249.656 95 251 93.6575 251 92C251 90.3425 249.656 89 248 89C246.344 89 245 90.3425 245 92ZM236 92C236 93.6575 237.342 95 239 95C240.658 95 242 93.6575 242 92C242 90.3425 240.658 89 239 89C237.342 89 236 90.3425 236 92ZM227 92C227 93.6575 228.342 95 230 95C231.658 95 233 93.6575 233 92C233 90.3425 231.658 89 230 89C228.342 89 227 90.3425 227 92Z"
        fill="currentColor"
        className={styles.neutral3}
      />
      <rect
        x="53"
        y="86"
        width="95"
        height="12"
        rx="6"
        fill="currentColor"
        className={styles.neutral3}
      />
      <rect
        x="129"
        y="123"
        width="95"
        height="12"
        rx="6"
        fill="currentColor"
        className={styles.neutral3}
      />
      <rect
        x="235"
        y="123"
        width="12"
        height="12"
        rx="6"
        fill="currentColor"
        className={styles.neutral3}
      />
      <path
        d="M129 129C129 125.686 131.686 123 135 123H174.5V135H135C131.686 135 129 132.314 129 129V129Z"
        fill="currentColor"
        className={styles.neutral7}
      />
      <path
        d="M55 129C55 125.686 57.6863 123 61 123H94.5C97.8137 123 100.5 125.686 100.5 129V129C100.5 132.314 97.8137 135 94.5 135H61C57.6863 135 55 132.314 55 129V129Z"
        fill="currentColor"
        className={styles.neutral7}
      />
    </g>
  );
};

export default DataCard;
