import React from 'react';
import styles from '../../SvgIllustration.module.scss';

const Selects = () => {
  return (
    <g className={'illustrationLayer'} fill="none" fillRule="evenodd">
      <path
        d="M39 116C39 109.373 44.3726 104 51 104H253C259.627 104 265 109.373 265 116V132C265 138.627 259.627 144 253 144H51C44.3726 144 39 138.627 39 132V116Z"
        className={styles.neutral3}
        fill="currentColor"
      />
      <path
        d="M41 116C41 110.477 45.4772 106 51 106H253C258.523 106 263 110.477 263 116V132C263 137.523 258.523 142 253 142H51C45.4771 142 41 137.523 41 132V116Z"
        className={styles.whiteBlack}
        fill="currentColor"
      />
      <rect
        x="39"
        y="48"
        width="226"
        height="40"
        rx="12"
        className={styles.whiteBlack}
        fill="currentColor"
      />
      <rect
        x="39"
        y="48"
        width="226"
        height="40"
        rx="12"
        className={styles.neutral3}
        fill="currentColor"
      />
      <rect
        x="41"
        y="50"
        width="222"
        height="36"
        rx="10"
        className={styles.whiteBlack}
        fill="currentColor"
      />
      <rect
        x="51"
        y="62"
        width="118"
        height="12"
        rx="6"
        className={styles.neutral3}
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M240.864 68.7426L246.167 63.4393C246.753 62.8536 247.703 62.8536 248.289 63.4393C248.874 64.0251 248.874 64.9749 248.289 65.5607L241.925 71.9246C241.339 72.5104 240.389 72.5104 239.803 71.9246L233.439 65.5607C232.854 64.9749 232.854 64.0251 233.439 63.4393C234.025 62.8536 234.975 62.8536 235.561 63.4393L240.864 68.7426Z"
        fill="#8C96A0"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M240.864 124.743L246.167 119.439C246.753 118.854 247.703 118.854 248.289 119.439C248.874 120.025 248.874 120.975 248.289 121.561L241.925 127.925C241.339 128.51 240.389 128.51 239.803 127.925L233.439 121.561C232.854 120.975 232.854 120.025 233.439 119.439C234.025 118.854 234.975 118.854 235.561 119.439L240.864 124.743Z"
        fill="#8C96A0"
      />
      <rect
        x="51"
        y="114"
        width="61"
        height="20"
        rx="6"
        className={styles.neutral3}
        fill="currentColor"
      />
      <rect
        x="118"
        y="114"
        width="85"
        height="20"
        rx="6"
        className={styles.neutral3}
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M103.04 122.561L104.76 120.841C105.167 120.434 105.828 120.434 106.235 120.841C106.642
        121.248 106.642 121.908 106.235 122.315L104.514 124.036L106.235 125.756C106.642 126.163
        106.642 126.823 106.235 127.23C105.828 127.637 105.167 127.637 104.76 127.23L103.04
        125.51L101.32 127.23C100.913 127.637 100.253 127.637 99.8454 127.23C99.4382 126.823 99.4382
        126.163 99.8454 125.756L101.566 124.036L99.8454 122.315C99.4382 121.908 99.4382 121.248
        99.8454 120.841C100.253 120.434 100.913 120.434 101.32 120.841L103.04 122.561Z"
        className={styles.blackWhite}
        fill="currentColor"
        fillOpacity="0.8"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M194.04 122.561L195.76 120.841C196.167 120.434 196.828 120.434 197.235 120.841C197.642
        121.248 197.642 121.908 197.235 122.315L195.514 124.036L197.235 125.756C197.642 126.163
        197.642 126.823 197.235 127.23C196.828 127.637 196.167 127.637 195.76 127.23L194.04
        125.51L192.32 127.23C191.913 127.637 191.253 127.637 190.845 127.23C190.438 126.823
        190.438 126.163 190.845 125.756L192.566 124.036L190.845 122.315C190.438 121.908 190.438
        121.248 190.845 120.841C191.253 120.434 191.913 120.434 192.32 120.841L194.04 122.561Z"
        className={styles.blackWhite}
        fill="currentColor"
        fillOpacity="0.8"
      />
      <rect
        x="57"
        y="121"
        width="34"
        height="6"
        rx="3"
        className={styles.blackWhite}
        fill="currentColor"
      />
      <rect
        x="125"
        y="121"
        width="57"
        height="6"
        rx="3"
        className={styles.blackWhite}
        fill="currentColor"
      />
    </g>
  );
};

export default Selects;
