import React from 'react';
import styles from '../../SvgIllustration.module.scss';

const IconFeaturesNavigation = () => {
  return (
    <g className={'illustrationLayer'} fill="none" fillRule="evenodd">
      <path
        id="Mask"
        className={styles.neutral7}
        fill="currentColor"
        fillRule="nonzero"
        d="M125.578 91.0352C125.332 91.2245 125.188 91.5173 125.188 91.8278V96.5229C125.188 97.0752 125.635 97.5229
        126.188 97.5229H131.375V119.77C131.375 120.323 131.823 120.77 132.375 120.77H145.812V101.145C145.812 100.593
        146.26 100.145 146.812 100.145H157.188C157.74 100.145 158.188 100.593 158.188
        101.145V120.77H171.625C172.177 120.77 172.625 120.323 172.625 119.77V97.5229H177.812C178.365
        97.5229 178.812 97.0752 178.812 96.5229V91.8278C178.812 91.5173 178.668 91.2245 178.422 91.0352L152.61
        71.1794C152.25 70.9029 151.75 70.9029 151.39 71.1794L125.578 91.0352Z"
      />
    </g>
  );
};

export default IconFeaturesNavigation;
