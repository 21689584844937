import React from 'react';
import styles from '../../SvgIllustration.module.scss';

const ParentCheckbox = () => {
  return (
    <g className={'illustrationLayer'} fill="none" fillRule="evenodd">
      <g id="Group">
        <path
          className={styles.neutral5}
          fillRule="evenodd"
          clipRule="evenodd"
          d="M121 82H107C103.134 82 100 85.134 100 89V103C100 106.866 103.134 110 107 110H121C124.866 110 128 106.866 128 103V89C128 85.134 124.866 82 121 82ZM103.818 89C103.818 87.2427 105.243 85.8182 107 85.8182H121C122.757 85.8182 124.182 87.2427 124.182 89V103C124.182 104.757 122.757 106.182 121 106.182H107C105.243 106.182 103.818 104.757 103.818 103V89Z"
          fill="#8C96A0"
        />
        <path
          className={styles.neutral9}
          fillRule="evenodd"
          clipRule="evenodd"
          d="M83 42H69C65.134 42 62 45.134 62 49V63C62 66.866 65.134 70 69 70H83C86.866 70 90 66.866 90 63V49C90 45.134 86.866 42 83 42ZM74.3754 57.8382C73.9235 58.3767 73.1208 58.4469 72.5823 57.9951L69.2674 55.2136C68.729 54.7618 67.9262 54.832 67.4744 55.3705L66.1654 56.9304C65.7136 57.4689 65.7838 58.2716 66.3223 58.7235L73.147 64.4501C73.6855 64.9019 74.4883 64.8317 74.9401 64.2932L85.9025 51.2287C86.3544 50.6903 86.2841 49.8875 85.7457 49.4357L84.1857 48.1267C83.6473 47.6749 82.8445 47.7451 82.3927 48.2836L74.3754 57.8382Z"
          fill="#13293F"
        />
        <path
          className={styles.neutral4}
          fillRule="evenodd"
          clipRule="evenodd"
          d="M100 57C100 53.134 103.134 50 107 50H223C226.866 50 230 53.134 230 57V57C230 60.866 226.866 64 223 64H107C103.134 64 100 60.866 100 57V57Z"
          fill="#A5ADB5"
        />
        <path
          className={styles.neutral9}
          fillRule="evenodd"
          clipRule="evenodd"
          d="M121 122H107C103.134 122 100 125.134 100 129V143C100 146.866 103.134 150 107 150H121C124.866 150 128 146.866 128 143V129C128 125.134 124.866 122 121 122ZM112.375 137.838C111.924 138.377 111.121 138.447 110.582 137.995L107.267 135.214C106.729 134.762 105.926 134.832 105.474 135.37L104.165 136.93C103.714 137.469 103.784 138.272 104.322 138.723L111.147 144.45C111.685 144.902 112.488 144.832 112.94 144.293L123.903 131.229C124.354 130.69 124.284 129.887 123.746 129.436L122.186 128.127C121.647 127.675 120.844 127.745 120.393 128.284L112.375 137.838Z"
          fill="#13293F"
        />
        <rect
          className={styles.neutral4}
          x="138"
          y="130"
          width="143"
          height="14"
          rx="7"
          fill="#A5ADB5"
        />
        <rect
          className={styles.neutral4}
          x="138"
          y="90"
          width="122"
          height="14"
          rx="7"
          fill="#A5ADB5"
        />
      </g>
    </g>
  );
};

export default ParentCheckbox;
