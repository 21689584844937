import React from 'react';
import styles from '../../SvgIllustration.module.scss';

/* eslint-disable */
const AccessibilityAudit = () => {
  return (
    <g className={'illustrationLayer'} fill="none" fillRule="evenodd">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M90 56C90 48.8314 95.8324 43 103 43C110.169 43 116 48.8314 116 56C116 63.1686 110.169 69 103 69C95.8324 69 90 63.1686 90 56ZM107.889 50.6895L100.728 59.2249L97.8789 56.8357C97.4926 56.5107 96.903 56.559 96.5734 56.9509L95.9763 57.664C95.6439 58.0605 95.6968 58.6409 96.0886 58.9696L100.361 62.5548C100.554 62.7164 100.798 62.786 101.036 62.7656L101.038 62.7674C101.275 62.7461 101.503 62.6337 101.668 62.4378L110.023 52.4798C110.355 52.0851 110.302 51.5011 109.909 51.1724L109.197 50.5734C108.801 50.2419 108.219 50.2967 107.889 50.6895Z"
        className={styles.neutral6}
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M90 136C90 128.831 95.8324 123 103 123C110.169 123 116 128.831 116 136C116 143.169 110.169 149 103 149C95.8324 149 90 143.169 90 136ZM107.889 130.69L100.728 139.225L97.8789 136.836C97.4926 136.511 96.903 136.559 96.5734 136.951L95.9763 137.664C95.6439 138.061 95.6968 138.641 96.0886 138.97L100.361 142.555C100.554 142.716 100.798 142.786 101.036 142.766L101.038 142.767C101.275 142.746 101.503 142.634 101.668 142.438L110.023 132.48C110.355 132.085 110.302 131.501 109.909 131.172L109.197 130.573C108.801 130.242 108.219 130.297 107.889 130.69Z"
        className={styles.neutral6}
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M101.283 83.3215C101.756 82.4519 102.379 82.0186 103 82.0186C103.622 82.0186 104.245 82.4538 104.717 83.3215L116.69 105.297C117.504 106.791 116.783 108.003 115.09 108.003H90.9104C89.2128 108.003 88.497 106.788 89.3105 105.297L101.283 83.3215ZM101.97 98.647C101.97 99.216 102.431 99.68 103 99.68C103.573 99.68 104.03 99.218 104.03 98.647L104.675 91.181C104.746 90.249 104.043 89.5 103.106 89.5H102.894C101.962 89.5 101.255 90.252 101.325 91.181L101.97 98.647ZM101.3 103C101.3 103.943 102.057 104.7 103 104.7C103.942 104.7 104.7 103.943 104.7 103C104.7 102.058 103.942 101.3 103 101.3C102.057 101.3 101.3 102.058 101.3 103Z"
        className={styles.neutral9}
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M127 56C127 52.134 130.134 49 134 49H210C213.866 49 217 52.134 217 56V56C217 59.866 213.866 63 210 63H134C130.134 63 127 59.866 127 56V56Z"
        className={styles.neutral4}
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M127 136C127 132.134 130.134 129 134 129H201C204.866 129 208 132.134 208 136V136C208 139.866 204.866 143 201 143H134C130.134 143 127 139.866 127 136V136Z"
        className={styles.neutral4}
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M127 96C127 92.134 130.134 89 134 89H188C191.866 89 195 92.134 195 96V96C195 99.866 191.866 103 188 103H134C130.134 103 127 99.866 127 96V96Z"
        className={styles.neutral4}
        fill="currentColor"
      />
    </g>
  );
};

export default AccessibilityAudit;
