import React from 'react';
import styles from '../../SvgIllustration.module.scss';
/* eslint-disable */
const AccessibilityColor = () => {
  return (
    <g className={'illustrationLayer'} fill="none" fillRule="evenodd">
      <path
        d="M199 96C199 121.957 177.957 143 152 143C126.043 143 105 121.957 105 96C105 70.0426 126.043 49 152 49C177.957 49 199 70.0426 199 96Z"
        fill="currentColor"
        className={styles.action}
      />
      <path
        d="M119.349 96C119.349 114.033 133.967 128.651 152 128.651V63.3488C133.967 63.3488 119.349 77.9673 119.349 96Z"
        fill="currentColor"
        className={styles.neutral1}
      />
    </g>
  );
};

export default AccessibilityColor;
