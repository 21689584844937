import React from 'react';
// import styles from '../../SvgIllustration.module.scss';

const Divider = () => {
  return (
    <g
      id="Divider"
      className={'illustrationLayer'}
      fill="none"
      fillRule="evenodd">
      <path
        id="Rectangle 2"
        d="M46 96C46 94.3431 47.3431 93 49 93H243C244.657
        93 246 94.3431 246 96V96C246 97.6569 244.657 99 243 99H49C47.3431 99 46 97.6569 46 96V96Z"
        fill="#DFE5EB"
      />
      <rect
        id="Rectangle 5"
        x="46"
        y="33"
        width="32"
        height="16"
        rx="8"
        fill="#B7BFC7"
      />
      <rect
        id="Rectangle 11"
        x="46"
        y="144"
        width="60"
        height="16"
        rx="8"
        fill="#B7BFC7"
      />
      <rect
        id="Rectangle 6"
        x="87"
        y="33"
        width="112"
        height="16"
        rx="8"
        fill="#B7BFC7"
      />
      <rect
        id="Rectangle 7"
        x="207"
        y="33"
        width="52"
        height="16"
        rx="8"
        fill="#B7BFC7"
      />
      <rect
        id="Rectangle 12"
        x="114"
        y="144"
        width="52"
        height="16"
        rx="8"
        fill="#B7BFC7"
      />
      <rect
        id="Rectangle 8"
        x="46"
        y="57"
        width="117"
        height="16"
        rx="8"
        fill="#B7BFC7"
      />
      <rect
        id="Rectangle 9"
        x="46"
        y="119"
        width="101"
        height="16"
        rx="8"
        fill="#B7BFC7"
      />
      <rect
        id="Rectangle 10"
        x="170"
        y="57"
        width="62"
        height="16"
        rx="8"
        fill="#B7BFC7"
      />
      <path
        id="Rectangle 4"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M153 127C153 122.582 156.582 119 161
        119H207C211.418 119 215 122.582 215 127V127C215 131.418 211.418 135 207 135H161C156.582 135 153 131.418
        153 127V127Z"
        fill="#B7BFC7"
      />
    </g>
  );
};

export default Divider;
