/* eslint-disable max-len */
import React from 'react';
import styles from '../../SvgIllustration.module.scss';

const NumberList = () => {
  return (
    <g className={'illustrationLayer'} fill="none" fillRule="evenodd">
      <path d="M62 90C58.6863 90 56 92.6863 56 96C56 99.3137 58.6863 102 62 102H137C140.314 102 143 99.3137 143 96C143 92.6863 140.314 90 137 90H62Z" fill="currentColor" className={styles.action}/>
      <path d="M198.5 96C198.5 92.6863 201.186 90 204.5 90H219C222.314 90 225 92.6863 225 96C225 99.3137 222.314 102 219 102H204.5C201.186 102 198.5 99.3137 198.5 96Z" fill="currentColor" className={styles.action}/>
      <path d="M240.323 97.0395L244.908 91.3564C245.166 91.0362 245.627 90.9958 245.937 91.2664L246.835 92.0501C247.145 92.3207 247.187 92.7996 246.929 93.1199L240.66 100.891C240.401 101.211 239.941 101.251 239.631 100.981L235.701 97.5517C235.39 97.2811 235.349 96.8022 235.607 96.4819L236.355 95.5541C236.614 95.2338 237.075 95.1935 237.385 95.464L239.294 97.1295C239.604 97.4001 240.065 97.3597 240.323 97.0395Z" fill="currentColor" className={styles.action}/>
      <path d="M204.5 61C201.186 61 198.5 63.6863 198.5 67C198.5 70.3137 201.186 73 204.5 73H219C222.314 73 225 70.3137 225 67C225 63.6863 222.314 61 219 61H204.5Z" fill="currentColor" className={styles.neutral3}/>
      <path d="M204.5 119C201.186 119 198.5 121.686 198.5 125C198.5 128.314 201.186 131 204.5 131H219C222.314 131 225 128.314 225 125C225 121.686 222.314 119 219 119H204.5Z" fill="currentColor" className={styles.neutral3}/>
      <path d="M236 67C236 63.6863 238.686 61 242 61C245.314 61 248 63.6863 248 67C248 70.3137 245.314 73 242 73C238.686 73 236 70.3137 236 67Z" fill="currentColor" className={styles.neutral3}/>
      <path d="M242 119C238.686 119 236 121.686 236 125C236 128.314 238.686 131 242 131C245.314 131 248 128.314 248 125C248 121.686 245.314 119 242 119Z" fill="currentColor" className={styles.neutral3}/>
      <path d="M62 61C58.6863 61 56 63.6863 56 67C56 70.3137 58.6863 73 62 73H156C159.314 73 162 70.3137 162 67C162 63.6863 159.314 61 156 61H62Z" fill="currentColor" className={styles.neutral7}/>
      <path d="M62 119C58.6863 119 56 121.686 56 125C56 128.314 58.6863 131 62 131H95.5C98.8137 131 101.5 128.314 101.5 125C101.5 121.686 98.8137 119 95.5 119H62Z" fill="currentColor" className={styles.neutral7}/>
    </g>
  );
};

export default NumberList;
