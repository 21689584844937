import React from 'react';
import styles from '../../SvgIllustration.module.scss';

const IconFeedback = () => {
  return (
    <g className={'illustrationLayer'} fill="none" fillRule="evenodd">
      <path
        id="Mask"
        className={styles.neutral7}
        fill="currentColor"
        fillRule="nonzero"
        d="M148.459 69.8506C149.435 68.0571 150.72 67.1633 152 67.1633C153.283 67.1633 154.568
        68.0611 155.541 69.8506L180.235 115.174C181.915 118.257 180.427 120.756 176.936
        120.756H127.065C123.564 120.756 122.088 118.251 123.766 115.174L148.459 69.8506ZM149.876
        101.459C149.876 102.633 150.827 103.59 152 103.59C153.182 103.59 154.125 102.637 154.125
        101.459L155.455 86.0609C155.601 84.1386 154.152 82.5938 152.219 82.5938H151.782C149.859
        82.5938 148.401 84.1448 148.546 86.0609L149.876 101.459ZM148.494 110.438C148.494 112.382
        150.055 113.944 152 113.944C153.943 113.944 155.507 112.382 155.507 110.438C155.507
        108.495 153.943 106.931 152 106.931C150.055 106.931 148.494 108.495 148.494 110.438Z"
      />
    </g>
  );
};

export default IconFeedback;
