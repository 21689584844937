import React from 'react';
import styles from '../../SvgIllustration.module.scss';

const IconPermissions = () => {
  return (
    <g className={'illustrationLayer'} fill="none" fillRule="evenodd">
      <path
        id="Mask"
        className={styles.neutral7}
        fill="currentColor"
        fillRule="nonzero"
        d="M136 97.3643V89C136 80.1634 143.163 73 152 73C160.837 73 168 80.1634 168 89V97.3643H170C172.209
        97.3643 174 99.1552 174 101.364V121.364C174 123.573 172.209 125.364 170 125.364H134C131.791 125.364
        130 123.573 130 121.364V101.364C130 99.1552 131.791 97.3643 134 97.3643H136ZM142 97.3643H162V89C162
        83.4772 157.523 79 152 79C146.477 79 142 83.4772 142 89V97.3643Z"
      />
    </g>
  );
};

export default IconPermissions;
