/* eslint-disable max-len */
import React from 'react';
import styles from '../../SvgIllustration.module.scss';

const SegmentedBar = () => {
  return (
    <g className={'illustrationLayer'} fill="none" fillRule="evenodd">
      <path
        d="M48 95.5C48 89.701 52.701 85 58.5 85L122.51 85C123.333 85 124 85.6672 124 86.4902L124 104.51C124 105.333 123.333 106 122.51 106L58.5 106C52.701 106 48 101.299 48 95.5V95.5Z"
        fill="currentColor"
        className={styles.neutral3}
      />
      <path
        d="M128 87C128 85.8954 128.895 85 130 85L196 85C197.105 85 198 85.8954 198 87L198 104C198 105.105 197.105 106 196 106L130 106C128.895 106 128 105.105 128 104L128 87Z"
        fill="currentColor"
        className={styles.action}
      />
      <path
        d="M202 86.0784C202 85.4828 202.483 85 203.078 85L246.5 85C252.299 85 257 89.701 257 95.5V95.5C257 101.299 252.299 106 246.5 106L203.078 106C202.483 106 202 105.517 202 104.922L202 86.0784Z"
        fill="currentColor"
        className={styles.neutral3}
      />
    </g>
  );
};

export default SegmentedBar;
