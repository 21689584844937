import React from 'react';
import styles from '../../SvgIllustration.module.scss';

const IconVideoFeatures = () => {
  return (
    <g className={'illustrationLayer'} fill="none" fillRule="evenodd">
      <path
        id="Mask"
        className={styles.neutral7}
        fill="currentColor"
        fillRule="nonzero"
        d="M152.066 92.1493L172.577 79.7079C174.5 78.5416 176.971 78.8397 178.561 80.43C178.993
        80.8622 178.94 81.5776 178.449 81.9411L158.42 96.755L179.449 112.309C179.94 112.672 179.993
        113.387 179.561 113.82C177.971 115.41 175.5 115.708 173.577 114.542L150.784 100.717L150.567
        100.863L143.657 105.531C146.229 109.816 145.019 115.362 140.735 118.255C136.212 121.31
        129.978 120.25 126.81 115.886C123.643 111.523 124.741 105.509 129.265 102.453C132.37
        100.357 136.28 100.2 139.451 101.716L147.915 96.0003L139.451 90.2836C136.28 91.8009 132.369
        91.6437 129.265 89.5469C124.74 86.4909 123.642 80.4768 126.809 76.1134C129.977 71.7501
        136.211 70.69 140.735 73.7448C145.019 76.6382 146.229 82.1836 143.656 86.469L150.567
        91.1371L152.066 92.1493ZM135.022 86.6671C137.885 86.6671 140.205 84.4284 140.205 81.667C140.205
        78.9057 137.885 76.667 135.022 76.667C132.159 76.667 129.838 78.9057 129.838 81.667C129.838
        84.4284 132.159 86.6671 135.022 86.6671ZM135.022 115.334C137.885 115.334 140.205 113.095
        140.205 110.334C140.205 107.573 137.885 105.334 135.022 105.334C132.159 105.334 129.838
        107.573 129.838 110.334C129.838 113.095 132.159 115.334 135.022 115.334Z"
      />
    </g>
  );
};

export default IconVideoFeatures;
