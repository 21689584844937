/* eslint-disable max-len */
import React from 'react';
import styles from '../../SvgIllustration.module.scss';

const BarList = () => {
  return (
    <g className={'illustrationLayer'} fill="none" fillRule="evenodd">
      <path d="M62 90C58.6863 90 56 92.6863 56 96C56 99.3137 58.6863 102 62 102H115C118.314 102 121 99.3137 121 96C121 92.6863 118.314 90 115 90H62Z" fill="currentColor" className={styles.action} />
      <path d="M151 96C151 92.6863 153.686 90 157 90H180V102H157C153.686 102 151 99.3137 151 96Z" fill="currentColor" className={styles.action} />
      <path d="M219 90H182.5V102H219C222.314 102 225 99.3137 225 96C225 92.6863 222.314 90 219 90Z" fill="currentColor" className={styles.action} />
      <path d="M244.908 91.4297L240.323 97.1127C240.065 97.433 239.604 97.4733 239.294 97.2028L237.385 95.5373C237.075 95.2667 236.614 95.307 236.355 95.6273L235.607 96.5552C235.349 96.8754 235.39 97.3544 235.701 97.6249L239.631 101.054C239.941 101.324 240.401 101.284 240.66 100.964L246.929 93.1931C247.187 92.8729 247.145 92.3939 246.835 92.1234L245.937 91.3396C245.627 91.0691 245.166 91.1094 244.908 91.4297Z" fill="currentColor" className={styles.action} />
      <path d="M198.5 62H219C222.314 62 225 64.6863 225 68C225 71.3137 222.314 74 219 74H198.5V62Z" fill="currentColor" className={styles.neutral3} />
      <path d="M236 68C236 64.6863 238.686 62 242 62C245.314 62 248 64.6863 248 68C248 71.3137 245.314 74 242 74C238.686 74 236 71.3137 236 68Z" fill="currentColor" className={styles.neutral3} />
      <path d="M162 118H219C222.314 118 225 120.686 225 124C225 127.314 222.314 130 219 130H162V118Z" fill="currentColor" className={styles.neutral3} />
      <path d="M236 124C236 120.686 238.686 118 242 118C245.314 118 248 120.686 248 124C248 127.314 245.314 130 242 130C238.686 130 236 127.314 236 124Z" fill="currentColor" className={styles.neutral3} />
      <path d="M62 62C58.6863 62 56 64.6863 56 68C56 71.3137 58.6863 74 62 74H115C118.314 74 121 71.3137 121 68C121 64.6863 118.314 62 115 62H62Z" fill="currentColor" className={styles.neutral7} />
      <path d="M157 62C153.686 62 151 64.6863 151 68C151 71.3137 153.686 74 157 74H196V62H157Z" fill="currentColor" className={styles.neutral7} />
      <path d="M151 124C151 120.686 153.686 118 157 118H159.5V130H157C153.686 130 151 127.314 151 124Z" fill="currentColor" className={styles.neutral7} />
      <path d="M62 118C58.6863 118 56 120.686 56 124C56 127.314 58.6863 130 62 130H95.5C98.8137 130 101.5 127.314 101.5 124C101.5 120.686 98.8137 118 95.5 118H62Z" fill="currentColor" className={styles.neutral7} />
    </g>
  );
};

export default BarList;
