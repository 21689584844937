import React from 'react';
import styles from '../../SvgIllustration.module.scss';
/* eslint-disable */
const AccessibilityText = () => {
  return (
    <g className={'illustrationLayer'} fill="none" fillRule="evenodd">
      <path
        id="A"
        d="M104 146V137.346L111.418 136.316L146.376 46H157.984L192.324 136.316L199.673 137.346V146H170.964V137.346L178.519 136.041L171.926 117.635H131.885L125.085 136.041L132.64 137.346V146H104ZM136.074 106.302H167.805L153.107 65.6429L152.283 63.3764H151.871L151.047 65.6429L136.074 106.302Z"
        fill="currentColor"
        className={styles.contentDefault}
      />
    </g>
  );
};

export default AccessibilityText;
